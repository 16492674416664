import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
import * as auth from './redux/AuthRedux'
import * as customer from '../../redux/CustomersRedux'


export function Logout() {
  const dispatch = useDispatch()
  useEffect(() => {
    localStorage.removeItem('k')
    localStorage.removeItem('p')
    localStorage.removeItem('customerId')
    localStorage.removeItem('group')
    localStorage.removeItem('customerList')
    localStorage.removeItem('testDevice')
    localStorage.removeItem('maintenanceMode')

    dispatch(customer.actions.clearSelection());
    dispatch(auth.actions.logout())    
    window.location.reload();
  }, [dispatch])

  return (
    <Routes>
      <Route index element={<Navigate to='/auth/login' />}></Route>
    </Routes>
  )
}
