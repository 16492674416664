import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import _ from 'lodash';
import * as customer from '../../../../app/redux/CustomersRedux';
import { RootState } from '../../../../setup';

const SelectDevice: React.FC<any> = ({ divClass, labelclass, label, onChange, value }) => {
  const deviceGroup: any = useSelector<RootState>(({ customer }) => customer.deviceGroup, shallowEqual);
  const customerList: any = useSelector<RootState>(({ customer }) => customer.customerList, shallowEqual);

  const dispatch = useDispatch();

  const change = (e: any) => {
    dispatch(customer.actions.setDeviceGroup(e));
    if (onChange && typeof onChange == "function") {
      onChange(e);
    }
  }

  let selected = deviceGroup;
  if (value) {
    selected = _.find(customerList[0].DeviceGroups, { Id: value });
  }

  return (
    <>
      <div className={divClass ? divClass : 'd-flex align-items-center py-1 gap-3'}>
        <label className={labelclass ? labelclass : 'form-label fs-6 fw-bolder mb-0'}>{label ? label : "Device Group"}</label>
        <Select
          onChange={change}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          classNamePrefix='pg-select'
          className=' w-250px react-select-pg '
          options={customerList[0].DeviceGroups}
          getOptionLabel={(label) => label!.Name}
          getOptionValue={(value) => value!.Name}
          value={selected}
        ></Select>
      </div>
    </>
  )
}
export default SelectDevice;
