/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, { FC, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import CustomerList from '../../../../app/modules/components/customerList'
import * as customer from '../../../../app/redux/CustomersRedux';
import { getCustomerList } from '../../../../app/redux/api'
import { useLayout } from '../../core'
import { DefaultTitle } from '../header/page-title/DefaultTitle'
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../../../setup';
import { toAbsoluteUrl } from '../../../helpers';
import UserBadge from '../header/UserBadge';


const Toolbar2: FC = () => {
  const customerId: any = useSelector<RootState>(({ customer }) => customer.customerId, shallowEqual);
  const customerList: any = useSelector<RootState>(({ customer }) => customer.customerList, shallowEqual);
  const allCustomers: any = useSelector<RootState>(({ customer }) => customer.allCustomers, shallowEqual);
  const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual);

  // const [customerList, setCustomerList] = useState<any>([])
  const location = useLocation()
  const [showList, setShowList] = useState<boolean>(true);
  const [showToolbar, setShowToolbar] = useState<boolean>(true);
  const { classes } = useLayout();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname.includes("/edit/") ||
      location.pathname.includes("/store/add") ||
      location.pathname.includes("/admin/commands") ||
      location.pathname.includes("/customers") ||
      location.pathname.includes("/geo-zone/livemessage") ||
      location.pathname.includes("/admin/app/settings")) {
      setShowList(false);
      setShowToolbar(false);
    } else {
      setShowList(true);
      setShowToolbar(true);
    }
    // let ktWrapper: any = document.getElementById('kt_wrapper')
    // if (location.pathname.includes("/learn")) {
    //   ktWrapper.style.paddingTop = "65px";
    //   setShowToolbar(false);
    // } else {
    //   ktWrapper.style.paddingTop = "calc(65px + var(--kt-toolbar-height))";
    //   setShowToolbar(true);
    // }

  }, [location]);


  useEffect(() => {
  }, [customerId])


  useEffect(() => {
    getCustomerList()
      .then((data) => {
        // getDevices();
        if (data.data && data.data.Response) {
          if ((customerId && customerId.length == 0) || customerId == null) {
            dispatch(customer.actions.setCustomer(_.map(data.data.Response, 'Id'), data.data.Response));
          }
          const sortedCustomers = _.sortBy((data.data.Response), 'Name')
          // setCustomerList(sortedCustomers);
          dispatch(customer.actions.setAllCustomer(sortedCustomers));

        }
      })
      .catch((e) => {
      })
  }, []);

  const handleChange = (e: any) => {

    let customerIds: any = [e.value];
    let filterCustomerList: any = allCustomers?.filter((item: any) => item.Id == e.value);

    if (e.value == null) {
      customerIds = _.map(allCustomers, 'Id');
      filterCustomerList = allCustomers;
    }


    dispatch(customer.actions.setCustomer(customerIds, filterCustomerList));
    // if (e.value == null) {
    //   let customers = customerList.map((o: any) => o.Id);
    //   customerIds = customers;
    // }

  }


  return (
    <>
      {showToolbar &&
        <>
          <div
            className={clsx('cursor-pointer d-flex align-items-center')}
          >

            {/* <h3 className='mx-4 ml-0 mb-0'>{user?.UserContactName}</h3> */}
            {customerList && customerList.length == 1 && customerList[0].Logo ? (
              <img className="company-logo object-fit-contain" src={customerList[0].Logo} alt='metronic' />
            ) : (
              <img className="company-logo object-fit-contain" src={toAbsoluteUrl('/media/avatars/blank.png')} alt='metronic' />
            )}
            {allCustomers?.length > 1 && showList &&
              <div className='d-flex align-items-center py-1' id='customerDropDown'>

                &nbsp;&nbsp;<CustomerList customer={allCustomers} handleChange={handleChange} selectedCustomer={customerId} ></CustomerList>
              </div>
            }
            {allCustomers && allCustomers.length == 1 &&
              <div className='d-flex align-items-center py-1 m-1 px-2'>

                &nbsp;&nbsp; <h3 className='m-0'>{allCustomers[0].Name}</h3>
              </div>
            }
            {user &&
              <UserBadge user={user} position={'right'} customer={customerList} />
            }
          </div>
        </>
      }
    </>
  )
}

export { Toolbar2 }
