import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react'
import _ from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import { RootState } from '../../../../setup';

interface InputProps {
    customer: any,
    handleChange: any,
    selectedCustomer: any,
}


const CustomerList: React.FC<InputProps> = ({ customer, handleChange, selectedCustomer }) => {

    const customerId: any = useSelector<RootState>(({ customer }) => customer.customerId, shallowEqual)

    let customerList: any = [];

    customerList.push({ value: null, label: "All" });

    customer.map((c: any, i: any) => {
        customerList.push({ value: c.Id, label: c.Name });
    })

    const onSelect = (e: any) => {
        handleChange(e);
    }

    const style = {
        control: (base: any) => ({
            ...base,
            border: 0,
            // This line disable the blue border
            boxShadow: "none"
        }),
        menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
        menu: (base: any) => ({ ...base, minWidth: '250px' }),
    };

    useEffect(() => {
    }, [selectedCustomer])

    const DropdownIndicator = (props: any) => {
        return (
            <components.DropdownIndicator {...props}>
                <i className="fa fa-caret-down" />
            </components.DropdownIndicator>
        );
    };

    return (
        <>
            <Select onChange={onSelect} menuPortalTarget={document.body}
                value={selectedCustomer.length == 1 ? _.find(customerList, { value: selectedCustomer[0] }) : null}
                styles={style}
                classNamePrefix="pg-select"
                components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator
                }}
                className='react-select-pg custom-select' options={customerList}></Select>
        </>
    )
}

export default CustomerList
