import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'

import * as auth from '../../app/modules/auth'
import * as customer from '../../app/redux/CustomersRedux'
import * as playlist from '../../app/redux/PlayListRedux'
import * as message from '../../app/redux/MessageRedux'
import * as drag from '../../app/redux/DragRedux'



export const rootReducer = combineReducers({
  auth: auth.reducer,
  customer: customer.reducer,
  playlist: playlist.reducer,
  message: message.reducer,
  drag: drag.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga()])
}
