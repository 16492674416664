import React, { useEffect, useRef } from 'react'
import { getCSS, getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import ApexCharts, { ApexOptions } from 'apexcharts'
import VerifyCustomer from '../../components/LayoutComponent/VerifyCustomer'

interface appVersion {
  appVersion: any
  isError: boolean
}

const AppVersionChart: React.FC<appVersion> = ({ appVersion, isError }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))
    const count: [] = appVersion?.AppVersions?.map((item: any) => item?.Count)
    const version: [] = appVersion?.AppVersions?.map((item: any) => item?.Version)

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, count, version))
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, appVersion])

  return (
    <>
      <div className='card'>
        <div className='card-header border-0'>
          <h1 className='align-items-start d-flex flex-column mt-6'>
            <span className='card-label fw-bolder'>Mobile App Versions</span>
            <span className='text-muted fw-bold fs-7 mt-1'>Number of installation</span>
          </h1>
        </div>
        <div className='card-body'>
          <VerifyCustomer message="Please select customer.">
          </VerifyCustomer>
        
          {/* begin::Chart */}
          {(!isError && appVersion?.AppVersions) &&
            <div ref={chartRef} id='kt_charts_widget_1_chart' style={{ height: '350px' }} />
          }
          {/* end::Chart */}
        </div>
      </div>
    </>
  )
}

export default AppVersionChart

function getChartOptions(height: number, count: number[], appVersions: string[]): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-primary')

  return {
    series: [
      {
        name: 'Count',
        data: count,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '10%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: false,
      width: 5,
      colors: ['transparent'],
    },
    xaxis: {
      categories: appVersions,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + ''
        },
      },
    },
    colors: [baseColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}
