import React from 'react'


const UserBadge: React.FC<any> = ({ user, customer, position }) => {
    return (
        <>
            {
                position == 'left' && user && user.UserType && user.UserType.length == 1 && user.UserType == 'Company' && customer && customer.length == 1 && customer[0].SubscriptionLevel &&
                <div className='border card-rounded border-gray-300 btn btn-active-light-primary px-5 py-1 active'>
                    <h5 className='mb-0 text-primary'>
                        {customer[0].SubscriptionLevel}
                    </h5>
                </div>
            }
            {
                position == 'left' && user && user.UserType && user.UserType.length == 1 && user.UserType != 'Company' &&
                <div className='border card-rounded border-gray-300 btn btn-active-light-primary px-5 py-1 active'>
                    <h5 className='mb-0 text-primary'>
                        {user.UserType}
                    </h5>
                </div>
            }
            {
                position == 'right' && user && user.UserType && user.UserType.length == 1 && user.UserType == 'Admin' && customer && customer.length == 1 && customer[0].SubscriptionLevel &&
                <div className='border card-rounded border-gray-300 btn btn-active-light-primary px-5 py-1 active ml-5'>
                    <h5 className='mb-0 text-primary'>
                        {customer[0].SubscriptionLevel}
                    </h5>
                </div>
            }
        </>
    )
    // if (user) {
    // } else {
    //     return <></>
    // }
}

export default UserBadge
