import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PaymentSuccess = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/subscriptions');
        }, 3000);

        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <>
            <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom auth-bg position-x-center bgi-no-repeat bgi-size-cover bgi-attachment-fixed'
                style={{ backgroundSize: 'cover' }}>
                <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 m-auto d-flex flex-column align-items-center">
                    <img src="/media/logos/iToplight_logo.png" alt="iToplight Logo" className="w-50 img-fluid" />
                    <div className='text-center'>
                        <h1 className='text-dark mt-7'>Payment Successful</h1>
                        <h3 className='mt-7' style={{ lineHeight: 1.5 }}>
                            This window will now close
                            <br /> and you will return to the
                            <br /> iToplight Cloud Portal
                        </h3>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PaymentSuccess;