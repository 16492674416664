import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../setup'

const OverlayBanner: React.FC<any> = () => {

    const role = useSelector<RootState>(({ auth }) => auth.role, shallowEqual)

    return (
        role == "PG" ?
            <>
                <div className={``} style={{ height: 'fit-content', backgroundColor: process.env.REACT_APP_BANNER_BG_COLOR }}>
                    <h1 className='my-2 mx-15 fw-bolder text-white text-uppercase'>
                        {process.env.REACT_APP_BANNER}
                    </h1>
                </div>
            </> :
            <></>

    )
}

export default OverlayBanner
