import React, { useState } from 'react'
import _ from 'lodash'
import { deleteMessage } from '../../../../redux/api'
import DeleteMessageModal from './DeleteMessageModal'
import MessageListRow from './MessageListRow'
import clsx from 'clsx'

interface props {
    data: any
}

const TaxiIcon: React.FC<any> = ({ state }) => {
    return (
        <svg className={clsx({ 'nr-dimmed': state == "dimmed" })} width="37" height="11" viewBox="0 0 37 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.875 2.98577V3.48577H1.375H3.52557V9.99998V10.5H4.02557H6.10511H6.60511V9.99998V3.48577H8.75142H9.25142V2.98577V1.27271V0.772705H8.75142H1.375H0.875V1.27271V2.98577ZM12.6195 10.5H12.9869L13.0967 10.1495L13.561 8.66759H15.8405L16.3059 10.1498L16.4158 10.5H16.7829H19.0499H19.7463L19.5237 9.84013L16.5791 1.11286L16.4643 0.772705H16.1053H13.2971H12.9381L12.8233 1.11286L9.87874 9.84013L9.65611 10.5H10.3525H12.6195ZM15.0235 6.06532H14.3763L14.6996 5.03362L15.0235 6.06532ZM24.5974 1.02248L24.453 0.772705H24.1645H21.7867H20.9002L21.3588 1.53136L23.8382 5.63301L21.2936 9.73647L20.8201 10.5H21.7185H24.1219H24.4081L24.5531 10.2531L25.7924 8.14248L27.0316 10.2531L27.1766 10.5H27.4628H29.8833H30.7839L30.3076 9.73557L27.751 5.63218L30.2261 1.53106L30.6838 0.772705H29.798H27.4372H27.1503L27.0055 1.02048L25.7945 3.09336L24.5974 1.02248ZM35.5383 1.27271V0.772705H35.0383H32.9289H32.4289V1.27271V9.99998V10.5H32.9289H35.0383H35.5383V9.99998V1.27271Z" fill="#FFC700" stroke="#8C8C8C" />
        </svg>
    )
}

const KeepZone: React.FC<any> = ({ state }) => {
    return (
        <span className={clsx({ 'nr-dimmed': state == "dimmed" }, 'w-15px h-15px')} style={{ border: "2px solid #848383", display: "inline-block", borderRadius: "50%" }}></span>
    )
}

const GreenZone: React.FC<any> = ({ state }) => {
    return (
        <span className={clsx({ 'nr-dimmed': state == "dimmed" }, 'badge badge-success badge-circle w-15px h-15px')}></span>
    )
}

const RedZone: React.FC<any> = ({ state }) => {
    return (
        <span className={clsx({ 'nr-dimmed': state == "dimmed" }, 'badge badge-danger badge-circle w-15px h-15px')}></span>
    )
}

const MessageZones: React.FC<props> = ({ data }) => {


    const isKeep = _.find(data.StandardOutputStates, { State: "keep" });

    const isTaxi = _.find(data.StandardOutputStates, (o: any) => (o.Nr == 4 && (o.State == "on" || o.State == "dimmed")));

    const isGreen = _.find(data.StandardOutputStates, (o: any) => (o.Nr == 1 && (o.State == "on" || o.State == "dimmed")));

    const isRed = _.find(data.StandardOutputStates, (o: any) => (o.Nr == 2 && (o.State == "on" || o.State == "dimmed")));


    return (
        <div className='zone-align gap-1'>
            {isKeep && <KeepZone />}
            {isGreen && <GreenZone state={isGreen.State} />}
            {isRed && <RedZone state={isRed.State} />}
            {isTaxi && <TaxiIcon state={isTaxi.State} />}
        </div>
    )

}

export default MessageZones
