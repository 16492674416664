import { Icon } from 'leaflet'
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../../setup';
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { getDeviceInfo } from '../../../redux/api';

interface InputProps {
    device: any,
}

const MapDeviceInfo: React.FC<InputProps> = ({ device }) => {
    const customerId: any = useSelector<RootState>(({ customer }) => customer.customerId, shallowEqual);
    const role = useSelector<RootState>(({ auth }) => auth.role, shallowEqual)
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [deviceInfo, setDeviceInfo] = useState<any>({});

    React.useEffect(() => {
        let reqData: any = {
            customerId: customerId[0],
            id: device.Id
        }
        getDeviceInfo(reqData)
            .then(({ data: { Response } }) => {
                setIsLoading(false);
                if (Response.Results) {
                    setDeviceInfo(Response.Results[0]);
                }
            })
            .catch((e) => {
                setIsLoading(false)
            })

        return () => {
            setDeviceInfo({});
        }
    }, []);

    const generateVideoUrl = () => {

        //Remove Special characters

        let status = device.CallWord ? device.CallWord : '';

        const wordToRemove = ["offduty", "oncall", "hired", "vacant"];

        let regex = new RegExp("\\b" + wordToRemove.join('|') + "\\b", "gi");
        status = status.replace(regex, '');

        status = status.replace(/[^a-zA-Z]/g, "");

        return `https://api-itoplight-cloud.s3.amazonaws.com/campaign_videos/${status}.mp4`;
    }

    if (isLoading) {
        return <div className='' style={{ width: '300px', fontSize: "13px" }}>
            <h3 className='loading-text-line' style={{ width: '300px', height: '22px' }}></h3>
            <div className='row'>
                <div className='col-md-6 mb-3'>
                    <span className='loading-text-line' style={{ width: '150px', height: '15px' }}></span><br></br>
                    <span className='loading-text-line' style={{ width: '100px', height: '15px' }}></span>
                </div>
                <div className='col-md-6 mb-3'>
                    <span className='loading-text-line' style={{ width: '150px', height: '15px' }}></span><br></br>
                    <span className='loading-text-line' style={{ width: '100px', height: '15px' }}></span>
                </div>

                <div className='col-md-6 mb-3'>
                    <span className='loading-text-line' style={{ width: '150px', height: '15px' }}></span><br></br>
                    <span className='loading-text-line' style={{ width: '100px', height: '15px' }}></span>
                </div>
                <div className='col-md-6 mb-3'>
                    <span className='loading-text-line' style={{ width: '150px', height: '15px' }}></span><br></br>
                    <span className='loading-text-line' style={{ width: '100px', height: '15px' }}></span>
                </div>
                <div className='col-md-6 mb-3'>
                    <span className='loading-text-line' style={{ width: '150px', height: '15px' }}></span><br></br>
                    <span className='loading-text-line' style={{ width: '100px', height: '15px' }}></span>
                </div>
                <div className='col-md-12 mb-3'>
                    <span className='loading-text-line' style={{ width: '150px', height: '15px' }}></span><br></br>
                    <span className='loading-text-line' style={{ width: '70px', height: '15px' }}></span> &nbsp;&nbsp;<span className='loading-text-line' style={{ width: '180px', height: '15px' }}></span>
                    <span className='loading-text-line' style={{ width: '70px', height: '15px' }}></span> &nbsp;&nbsp;<span className='loading-text-line' style={{ width: '180px', height: '15px' }}></span>
                    <span className='loading-text-line' style={{ width: '70px', height: '15px' }}></span> &nbsp;&nbsp;<span className='loading-text-line' style={{ width: '180px', height: '15px' }}></span>
                </div>

                <div className='col-md-12 mb-3'>
                    <span className='loading-text-line' style={{ width: '300px', height: '175px' }}></span><br></br>
                </div>
            </div>
        </div>
    }
    return (
        <div className='' style={{ width: '300px', fontSize: "13px" }}>
            <h3>Device Info</h3>
            {role !== 'Advertiser' &&
                <div className='row'>
                    <div className='col-md-6 mb-3'>
                        <strong >Vehicle No: </strong><br />
                        {deviceInfo.VehicleNo}
                    </div>
                    <div className='col-md-6 mb-3'>
                        <strong>Serial No: </strong><br />
                        {deviceInfo.Serial}
                    </div>

                    <div className='col-md-6 mb-3'>
                        <strong>Last Login: </strong><br />
                        {deviceInfo.LastLogin ? <>


                            <OverlayTrigger
                                placement='top'
                                overlay={<Tooltip id='tooltip-user-name'>{moment(deviceInfo.LastLogin).format('DD MMM YYYY h:m a')}</Tooltip>}
                            >
                                <div className='symbol symbol-35px symbol-circle'>

                                    {moment(deviceInfo.LastLogin).fromNow()}
                                </div>
                            </OverlayTrigger>
                        </> : '--'}
                    </div>
                    <div className='col-md-6 mb-3'>
                        <strong>App Version: </strong><br />
                        {deviceInfo.AppVersion ? deviceInfo.AppVersion : '--'}
                    </div>
                    <div className='col-md-6 mb-3'>
                        <strong>Firmware: </strong><br />
                        {deviceInfo.Firmware}
                    </div>
                    {(deviceInfo.MessagesLastUpdated || deviceInfo.PlaylistsLastUpdated || deviceInfo.SchedulesLastUpdated) &&
                        <div className='col-md-12 mb-3'>
                            <strong>Last Updates: </strong><br />

                            {deviceInfo.MessagesLastUpdated &&
                                <><strong>Messages: </strong>{moment(deviceInfo.MessagesLastUpdated).format('DD MMM YYYY h:m a')}<br /></>
                            }
                            {deviceInfo.PlaylistsLastUpdated &&
                                <><strong>Playlists: </strong>{moment(deviceInfo.PlaylistsLastUpdated).format('DD MMM YYYY h:m a')}<br /></>
                            }

                            {deviceInfo.SchedulesLastUpdated &&
                                <><strong>Schedules: </strong> {moment(deviceInfo.SchedulesLastUpdated).format('DD MMM YYYY h:m a')}<br /></>
                            }
                        </div>
                    }
                </div>
            }

            <div className='row'>
                <div className='col-md-12' >
                    <ReactPlayer controls={true} width={300} playsinline={true} volume={0} muted={true} height={'auto'} loop={true} playing url={generateVideoUrl()} />
                </div>
            </div>
        </div>
    )
}


export default MapDeviceInfo
