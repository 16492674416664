import React from 'react';
import { useNavigate } from 'react-router-dom';
import MessageRender from '../../modules/components/Message/MessageRender';
import { KTSVG } from '../../../_metronic/helpers';

interface InputProps {
  command: any,
  messages: any,
  index: number,
  token: any
}

const CommandRow: React.FC<InputProps> = ({ index, command, token, messages }) => {
  const navigate = useNavigate();
  let playlist = command.Playlists[0];

  const firstMessage = playlist?.ShowAll?.[0] ??
    playlist?.ShowFront?.[0] ??
    playlist?.ShowRear?.[0] ??
    [];

  const extractMessagesIds = (playlists: any) => {
    let arrayMessages: any = [];
    // let totalMessages = 0;

    playlists.forEach((playlist: any) => {
      if (playlist.ShowFront) {
        arrayMessages.push(...playlist.ShowFront);
        // totalMessages += playlist.ShowFront.length;
      }
      if (playlist.ShowRear) {
        arrayMessages.push(...playlist.ShowRear);
        // totalMessages += playlist.ShowRear.length;
      }
      if (playlist.ShowAll) {
        arrayMessages.push(...playlist.ShowAll);
        // totalMessages += playlist.ShowAll.length;
      }
    });

    // command.TotalMessages = totalMessages;

    return arrayMessages.map((item: any) => item.MessageId);
  };

  const getTotalMessages = (playlists: any) => {
    let totalMessages = 0;

    playlists.forEach((playlist: any) => {
      if (playlist.ShowFront) {
        totalMessages += playlist.ShowFront.length;
      }
      if (playlist.ShowRear) {
        totalMessages += playlist.ShowRear.length;
      }
      if (playlist.ShowAll) {
        totalMessages += playlist.ShowAll.length;
      }
    });

    // command.TotalMessages = totalMessages;

    return totalMessages;
  };

  const isDynamic = () => {
    const ids = extractMessagesIds(command.Playlists);
    const filterMessagesByIds = messages.filter((message: any) => ids.includes(message.Id));
    const hasDynamicInputs: boolean = filterMessagesByIds.some((message: any) => message.DynamicInputs.length > 0);
    return hasDynamicInputs;
  }

  const redirection = (cmnd: any) => {
    if (cmnd.Playlists.length == 1) {
      navigate(`/mobile/playlist?token=${token}&id=${cmnd.Playlists[0].Id}`)
    }
    else if (cmnd.Playlists.length > 1) {
      navigate(`/mobile/showcommand?token=${token}&id=14${cmnd.Id}`)
    }
  }

  return (
    <>
      {command &&
        <div className="config-item" key={index} onClick={(e) => { redirection(command) }}>
          {/* <img src="vacant-logo.png" alt="Vacant" /> */}
          <div className='message-image'>
            <MessageRender message={firstMessage} />
          </div>
          <div className='command-title'>
            <h2>{String(command?.Label)}</h2>
            <p className='mobile-content'>{getTotalMessages(command.Playlists)} {getTotalMessages(command.Playlists) > 1 ? ' messages' : ' message'}{command.Playlists.length > 1 ? ', Multilist' : ''}</p>
            <p className='mobile-content'>{isDynamic() ? 'Dynamic' : ''}</p>
          </div>
          <div className='command-properties tablet-content'>
            {command.Lifetime > 0 &&
              <div className='property'>
                <KTSVG
                  path='/media/svg/general/lifetime.svg'
                  className='svg-icon-1 svg-icon-command'
                />
                <p className='property-text'>
                  {command.Lifetime > 0 ? command.Lifetime / 1000 : command.Lifetime}s
                </p>
              </div>
            }
            {command.Playlists.length > 1 &&
              <div className='property'>
                <KTSVG
                  path='/media/svg/general/multilist.svg'
                  className='svg-icon-1 svg-icon-command'
                />
                <p className='property-text'>
                  MULTI
                </p>
              </div>
            }
            {
              isDynamic() &&
              <div className='property'>
                <KTSVG
                  path='/media/svg/general/dynamic.svg'
                  className='svg-icon-1 svg-icon-command'
                />
                <p className='property-text'>
                  DYN
                </p>
              </div>
            }
            {getTotalMessages(command.Playlists) > 0 &&
              <div className='property'>
                <KTSVG
                  path='/media/svg/general/messages.svg'
                  className='svg-icon-1 svg-icon-command'
                />
                <p className='property-text'>
                  {getTotalMessages(command.Playlists)}
                </p>
              </div>
            }
          </div>
        </div>
      }
    </>
  )
}

export default CommandRow
