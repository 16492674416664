import React from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import moment from 'moment-timezone';
import MessageRender from './MessageRender';

interface inputProps {
    show: boolean,
    close: () => void,
    playlist: any,
}

const MessagesModal: React.FC<inputProps> = ({ show, close, playlist }) => {
    return (
        <>
            <Modal className='message-preview' centered={true} size="lg" show={show} onHide={close}>
                <ModalBody className='text-center overflow-scroll'>
                    <div className='d-flex flex-row gap-5 flex-wrap'>
                        {playlist?.ShowAll && playlist.ShowAll.map((msg: any, i: number) => {
                            return (
                                <React.Fragment key={`img-${playlist.Id}-${i}`} >
                                    <img src={msg.PreviewUrl}></img>
                                    {/* <MessageRender message={msg}></MessageRender> */}
                                </React.Fragment>
                            )
                        })}
                        {playlist?.ShowFront && playlist.ShowFront.map((msg: any, i: number) => {
                            return (
                                <React.Fragment key={`img-${playlist.Id}-${i}`} >
                                    <img src={msg.PreviewUrl}></img>
                                    {/* <MessageRender message={msg}></MessageRender> */}
                                </React.Fragment>
                            )
                        })}
                        {playlist?.ShowRear && playlist.ShowRear.map((msg: any, i: number) => {
                            return (
                                <React.Fragment key={`img-${playlist.Id}-${i}`} >
                                    <img src={msg.PreviewUrl}></img>
                                    {/* <MessageRender message={msg}></MessageRender> */}
                                </React.Fragment>
                            )
                        })}
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

export default MessagesModal;