import _ from 'lodash'
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import BootUpBadge from '../../../components/BootUpBadge';
import MessageRender from '../../../components/Message/MessageRender';
import PlaylistEditConfirmation from '../../../playlist/components/PlaylistEditConfirmation';
import clsx from 'clsx';
import MessagesModal from '../../../components/Message/MessagesModal';
import DynamicKeys from './DynamicKeys';
import DeviceBadge from '../DeviceBadge';

interface InputProps {
  playlist?: any,
  configuration: any,
  removePlaylist: (index: any) => void,
  index: number,
  isDashboard: boolean,
  type: any
}

const PlaylistRow: React.FC<InputProps> = ({ playlist, removePlaylist, index, configuration, isDashboard, type }) => {

  const [showWarning, setShowWarning] = useState<boolean>(false);
  const history = useNavigate()
  const [isMessagesModal, setIsMessagesModal] = useState(false);

  const confirm = () => {
    setShowWarning(false);
    history(`/playlists/edit/${playlist.Id}`);
  }

  const close = () => {
    setShowWarning(false);
  }

  return (
    <>
      <tr key={configuration?.PlaylistId} className={clsx('', type == 'integrators' ? 'bg-grey' : '')}>
        {type == 'normal' &&

          <>
            <td>{configuration?.PlaylistName} <BootUpBadge playlist={playlist} /></td>
            {isDashboard != true &&
              <td>{playlist?.Description} <DeviceBadge playlist={playlist} /></td>
            }
            <td className={clsx('', isDashboard == true ? 'd-flex images-preview' : '')}>
              <div className={clsx('d-flex flex-row', isDashboard != true ? 'flex-wrap' : 'msgs-parent')}>
                <div className={clsx('d-flex flex-row', isDashboard != true ? 'flex-wrap gap-5' : '')} style={isDashboard == true ? { maxWidth: '240px', display: 'flex', columnGap: '8px' } : {}}>
                  {playlist?.ShowAll && playlist.ShowAll.map((msg: any, i: number) => {
                    return (
                      <React.Fragment key={`img-${playlist.Id}-${i}`} >
                        <MessageRender message={msg} height={isDashboard == true ? '30px' : ''}></MessageRender>
                      </React.Fragment>
                    )
                  })}
                  {playlist?.ShowFront && playlist.ShowFront.map((msg: any, i: number) => {
                    return (
                      <React.Fragment key={`img-${playlist.Id}-${i}`} >
                        <MessageRender message={msg} height={isDashboard == true ? '30px' : ''}></MessageRender>
                      </React.Fragment>
                    )
                  })}
                  {playlist?.ShowRear && playlist.ShowRear.map((msg: any, i: number) => {
                    return (
                      <React.Fragment key={`img-${playlist.Id}-${i}`} >
                        <MessageRender message={msg} height={isDashboard == true ? '30px' : ''}></MessageRender>
                      </React.Fragment>
                    )
                  })}
                </div>
                <div className='imgs-expand'>
                  <div className='img-dots' onClick={(e) => { e.stopPropagation(); setIsMessagesModal(true) }}>
                    <div>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
              {/* {isDashboard == true &&
              <i style={{ cursor: "pointer" }} className="fas fa-ellipsis-h fs-2 d-flex align-items-center ml-4"></i>
            } */}
            </td>

            {isDashboard != true &&
              <td>
                <div className='d-flex gap-2'>
                  <button className='btn btn-sm btn-primary' onClick={() => setShowWarning(true)}>Edit</button>
                  <button
                    className='btn btn-sm btn-danger'
                    onClick={() => removePlaylist(index)}
                  >
                    Remove
                  </button>
                </div>
              </td>
            }
          </>
        }
        {type == 'integrators' &&
          <>
            <td>{playlist?.CallWord}</td>
            <td>{playlist?.Title}</td>
            <td>
              {playlist?.ShowAll && playlist?.ShowAll.length > 0 &&
                <>
                  {playlist?.ShowAll && playlist.ShowAll.map((msg: any, i: number) => {
                    return (
                      <React.Fragment key={`img-${playlist.Id}-${i}`} >

                        <DynamicKeys msg={msg} />
                      </React.Fragment>
                    )
                  })}
                </>
              }

              {playlist?.ShowFront && playlist?.ShowFront.length > 0 &&
                <>
                  {playlist?.ShowFront && playlist.ShowFront.map((msg: any, i: number) => {
                    return (
                      <React.Fragment key={`img-${playlist.Id}-${i}`} >

                        <DynamicKeys msg={msg} />
                      </React.Fragment>
                    )
                  })}
                </>
              }
              {playlist?.ShowRear && playlist?.ShowRear.length > 0 &&
                <>
                  {playlist?.ShowRear && playlist.ShowRear.map((msg: any, i: number) => {
                    return (
                      <React.Fragment key={`img-${playlist.Id}-${i}`} >

                        <DynamicKeys msg={msg} />
                      </React.Fragment>
                    )
                  })}
                </>
              }
            </td>
            <td className={clsx('',)}>
              <div className={clsx('d-flex flex-row flex-wrap',)}>
                <div className={clsx('d-flex flex-row flex-wrap gap-5 align-items-center',)} >
                  {playlist?.ShowAll && playlist?.ShowAll.length > 0 &&
                    <>
                      Front
                      & Rear
                      {playlist?.ShowAll && playlist.ShowAll.map((msg: any, i: number) => {
                        return (
                          <React.Fragment key={`img-${playlist.Id}-${i}`} >

                            <MessageRender message={msg}></MessageRender>
                          </React.Fragment>
                        )
                      })}
                    </>
                  }

                  {playlist?.ShowFront && playlist?.ShowFront.length > 0 &&
                    <div className="d-flex flex-row flex-wrap gap-5 align-items-center w-100">
                      Front
                      {playlist?.ShowFront && playlist.ShowFront.map((msg: any, i: number) => {
                        return (
                          <React.Fragment key={`img-${playlist.Id}-${i}`} >
                            <MessageRender message={msg}></MessageRender>
                          </React.Fragment>
                        )
                      })}
                      {playlist?.ShowRear && playlist?.ShowRear.length > 0 &&
                        <hr className="solid w-100 m-0" />
                      }
                    </div>
                  }
                  {playlist?.ShowRear && playlist?.ShowRear.length > 0 &&
                    <div className="d-flex flex-row flex-wrap gap-5 align-items-center w-100">
                      Rear
                      {playlist?.ShowRear && playlist.ShowRear.map((msg: any, i: number) => {
                        return (
                          <React.Fragment key={`img-${playlist.Id}-${i}`} >
                            <MessageRender message={msg}></MessageRender>
                          </React.Fragment>
                        )
                      })}
                    </div>
                  }
                </div>
                <div className='imgs-expand'>
                  <div className='img-dots' onClick={(e) => { e.stopPropagation(); setIsMessagesModal(true) }}>
                    <div>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </>
        }
      </tr >
      <PlaylistEditConfirmation isOpenModal={showWarning} confirm={confirm} close={close}></PlaylistEditConfirmation>
      <MessagesModal show={isMessagesModal} close={() => setIsMessagesModal(false)} playlist={playlist} />
    </>
  )
}

export default PlaylistRow
