export function LoadingBlock() {
  return (
    <>
      <div className='main-loading'>
        <div className='holder'>
          <div className='preloader'>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </>
  )
}
