import React from 'react'
import { Button, Modal } from 'react-bootstrap'

interface inputProps {
    show: boolean
    confirm: () => void
    close: () => void
}

const ConfirmChanges: React.FC<inputProps> = ({ show, confirm, close, }) => {
    return (
        <Modal size='lg' show={show} onHide={close} confirm={confirm}>
            <Modal.Body>
                <h1 className='text-primary'>Confirm Changes and Deploy</h1>
                <h5 className='mt-5'>Important:</h5>
                <p className='fw-1 mt-2'>
                    When you press the confirm button below, the modifications you made to your configuration will be sent to all your iToplights.
                </p>
                <div className='table-responsive mt-1'>

                </div>
                <h6 className='fw-1 mt-5'>Are you sure you want to proceed?</h6>
                <div className='d-flex gap-5 mt-7'>
                    <Button size='sm' variant='secondary' onClick={close}>
                        Cancel
                    </Button>
                    <Button size='sm' variant='primary' onClick={confirm}>
                        Yes, confirm changes and deploy
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ConfirmChanges
