import React, { useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../../setup';


const VerifyCustomer: React.FC<any> = ({ children, message, classList }) => {

    const customerId: any = useSelector<RootState>(({ customer }) => customer.customerId, shallowEqual);

    return (
        <>
            <div>
                {(customerId && customerId?.length > 1) ?
                    <div className={classList ? classList : 'mb-lg-15 alert alert-danger w-50 mt-5'}>
                        <div className='alert-text font-weight-bold'>
                            {message ? message : "Please select a customer first to show this page."}
                        </div>
                    </div>
                    :
                    <>
                        {children}
                    </>
                }
            </div>


        </>
    )
}

export default VerifyCustomer
