import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import { getStatistics } from '../../redux/api'
import DeviceMapPage from '../device/DeviceMap'
import AppVersionChart from './components/AppVersionChart'
import Cards from './components/Cards'
import FirmwareVersionChart from './components/FirmwareVersionChart'
import Configuration from '../configuration/Configuration'

const DashboardPage: React.FC = () => {
  const customerId: any = useSelector<RootState>(({ customer }) => customer.customerId, shallowEqual)

  const [statisticsData, setStatisticsData] = useState();

  const [totalDemoLights, setTotalDemoLights] = useState(0);
  const [totalPlaylist, setTotalPlaylist] = useState(0);
  const [totalMessages, setTotalMessages] = useState(0);
  const [totalAdViews, setTotalAdViews] = useState(0);

  const getSumOfArrayObj = (value: [], sumKey: string) => {
    return value.map((item) => item[sumKey]).reduce((prev, curr) => prev + curr, 0)
  }

  useEffect(() => {
    if (customerId?.length == 1) {
      getStatistics(customerId).then((res) => {
        if (res?.data?.Response?.Results) {
          setStatisticsData(res?.data?.Response?.Results)
          setTotalDemoLights(getSumOfArrayObj(res?.data?.Response?.Results?.NumberOfDevices, "Count"));

          const totalPlaylistObj = res?.data?.Response?.Results?.NumberOfPlaylists?.find(
            (obj: any) => obj.DeviceGroup === "Total");

          setTotalPlaylist(totalPlaylistObj?.Count === null ? 0 : totalPlaylistObj?.Count);

          const totalMessagesObj = res?.data?.Response?.Results?.NumberOfMessages?.find(
            (obj: any) => obj.DeviceGroup === "Total");
          setTotalMessages(totalMessagesObj?.Count === null ? 0 : totalMessagesObj?.Count);

        }
      })
    } else {
      setTotalDemoLights(0);
      setTotalPlaylist(0);
      setTotalMessages(0);
      setTotalMessages(0);
    }
  }, [customerId])

  const element = document.getElementById("forMapHeight");

  return (
    <>
      <Cards totalDemoLights={totalDemoLights} totalPlaylist={totalPlaylist} totalMessages={totalMessages} totalAdViews={0} />
      <div className='mt-13'>
        <div className='row gy-10'>
          <div className='col-md-6 col-12'>
            <div className='card h-100'>
              {/* <div className='card-header border-0'>
                <h1 className='align-items-start d-flex flex-column'>
                  <span className='card-label fw-bolder'>Mobile App Versions</span>
                  <span className='text-muted fw-bold fs-7 mt-1'>Number of installation</span>
                </h1>
              </div> */}
              <div className='card-body p-0'>
                <DeviceMapPage height={element?.offsetHeight+'px'}></DeviceMapPage>
              </div>
            </div>
          </div>
          <div className='col-md-6 col-12' id='forMapHeight'>
            <FirmwareVersionChart isError={customerId?.length !== 1 ? true : false} firmwareVersion={statisticsData} />
          </div>
        </div>
      </div>
      <div className='mt-13'>
        <div className='row gy-10'>
          <div className='col-md-6 col-12'>
            <AppVersionChart isError={customerId?.length !== 1 ? true : false} appVersion={statisticsData} />
          </div>
          <div className='col-md-6 col-12'>
            <Configuration isDashboard={true}/>
          </div>
        </div>
      </div>
    </>
  )
}
export default DashboardPage
