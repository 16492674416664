import _ from 'lodash'
import React, { useState } from 'react'
import { getMessageList } from '../../../../redux/api';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../../../setup';
import { toast } from 'react-toastify';
import DynamicKeysInfoModal from './DynamicKeysInfoModal';


interface InputProps {
    msg: any,
}

const DynamicKeys: React.FC<InputProps> = ({ msg }) => {
    const dynamicKeys = extractDynamicKeys(msg.Content);
    const [selectedMessage, setSelectedMessage]: any = useState(null);
    const [isOpenInfoModal, setIsInfoModal] = useState(false);
    const customerId: any = useSelector<RootState>(({ customer }) => customer.customerId, shallowEqual);

    const getMessage = (message: any) => {
        const data: any = {
            limit: 1,
            offset: 1,
            id: message.MessageId
        }

        getMessageList(customerId[0], data).then(({ data }) => {
            if (data.Status == 200) {
                const message = data.Response.Results[0];

                if (message) {
                    setSelectedMessage(message);
                    setIsInfoModal(true);
                } else {
                    toast.error("Error while fetching message");
                }

            }
        }).catch((err) => {
            toast.error("Error while fetching message");
        });
    }

    function extractDynamicKeys(content: any) {
        if (content) {
            const regex = /\[([^\]]+)]/g;
            const matches = content.match(regex);
            return matches ? matches.map((match: any) => match.slice(1, -1)) : [];
        }
    }

    const handleOpenInfoModal = (message: any) => {
        getMessage(message);
    }

    const handleCloseInfoModal = () => {
        setIsInfoModal(false);
        setSelectedMessage(null);
    }

    return (
        <div className='d-flex align-items-center'>
            {dynamicKeys && dynamicKeys.length > 0 && dynamicKeys.map((key: any, index: any) => (
                <span className='d-flex align-items-center' key={index}>
                    <span>{key}</span>
                    {index < dynamicKeys.length - 1 ? <>,&nbsp;</> : ''}
                </span>
            ))}
            {dynamicKeys && dynamicKeys.length > 0 &&
                <>
                    <div className='ml-8' onClick={() => handleOpenInfoModal(msg)}>
                        <i className="fas fa-info-circle" style={{ fontSize: '20px', lineHeight: 'normal', color: '#0f2744', cursor: 'pointer' }}></i>
                    </div>
                    <DynamicKeysInfoModal isOpenInfoModal={isOpenInfoModal} handleCloseInfoModal={handleCloseInfoModal} message={selectedMessage} />
                </>
            }
        </div >
    )
}

export default DynamicKeys
