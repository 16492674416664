import { Action } from '@reduxjs/toolkit'
import _ from 'lodash';
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export interface ActionWithPayload<T> extends Action {
    payload?: T
}

export const actionTypes = {
    setNewMessage: '[setNewMessage] Action',
    updateOutputMessage: '[updateOutputMessage] Action',
    editOutputMessage: '[editOutputMessage] Action',
    updateDynamicInput: '[updateDynamicInput] Action',
    updateContent: '[updateContent] Action',
    updateContentText: '[updateContentText] Action',
    updateTwoRowContentText: '[updateTwoRowContentText] Action',
    setValueFromEdit: '[setValueFromEdit] Action',


}



const initialPlaylistState: any = {
    messages: [],
    outputs: [{ Type: "Standard", Nr: null, State: null }],
    dynamicInputs: [],
    // dynamicInputs: [{ KeyName: null, DefaultValue: null }],
    requestData: [],
    content: [],
    contentText: "",
    contentFinal: "",
    count: 0,
    twoRowContentText: { firstRow: '', secondRow: '' },
}


const getContent = (text: string, inputs: any, stateContent: any) => {
    let oldContent = stateContent;
    let content: any = [];
    let textAry: any = [];
    let btnAry: any = [];

    if (text) {
        textAry = text.split(" ");
    }

    if (inputs.length > 0) {
        btnAry = _.filter(inputs, (o => { return o.KeyName && o.KeyName.length > 0 }));
    }

    content = [...textAry];

    if (btnAry.length > 0) {
        btnAry.map((data: any, i: number) => {
            let d: any = _.findIndex(oldContent, (o: any) => { return o.KeyName == data.KeyName });
            if (d >= 0) {
                content.splice(d, 0, data);
            } else {
                content.push(data);
            }
        });
    }

    let c: any = [];
    content.map((data: any, i: number) => {
        let temp: any = {};

        if (data.KeyName) {
            temp = data;
            temp.type = "button";
        } else {
            temp.KeyName = data;
            temp.type = "string";
        }
        c.push(temp);
    });

    return c;
}

const getTwoRowContent = (contentText: any) => {
    let jsonContent = ''
    if (contentText.firstRow || contentText.secondRow) {
        jsonContent = `${contentText.firstRow}|${contentText.secondRow}`;
    }

    return jsonContent;
}

const getFinalContent = (content: any) => {

    let textAry: any = [];

    content.map((data: any, i: number) => {
        if (data.type == "string") {
            textAry.push(`${data.KeyName}`);
        } else {
            textAry.push(`[${data.KeyName}]`);
        }
    });

    return textAry.join(" ");
}

const getFinalEditContent = (content: any, dynamicInputs: any) => {
    // const textAry = content ? content.trim().split(" ") : [];
    const textAry = content ? content.trim().split(/\s+(?![^\[]*\])/) : [];

    const plainText: any = [];
    let inputAry: any = [];
    let btnArray: any = [];

    textAry.map((text: any, i: number) => {
        var matches = text.match(/\[(.*?)\]/);
        if (matches) {
            const match = _.find(dynamicInputs, (o: any) => {
                return o.KeyName === matches[1]
            });

            if (match) {
                match.type = "button";
                inputAry.push(match);
                btnArray.push(match);
            }
        } else {
            inputAry.push({ type: "string", KeyName: text });
            plainText.push(text);
        }
    });

    const textString = plainText.join(" ");

    return { content: inputAry, contentText: textString, dynamicInputs: btnArray };
}

export const reducer = persistReducer(
    { storage, key: 'v1-dhg-message', whitelist: ['message'] },
    (state: any = initialPlaylistState, action: any) => {
        switch (action.type) {
            case actionTypes.updateOutputMessage: {
                const outputs = JSON.parse(JSON.stringify(action.payload));
                state.outputs = outputs;
                return { ...state, outputs }
            }
            case actionTypes.editOutputMessage: {
                const data = action.payload;
                const outputs: any = [];

                data.PowerOutputStates.map((o: any, i: number) => {
                    o.Type = "Power";
                    outputs.push(o);
                });

                data.StandardOutputStates.map((o: any, i: number) => {
                    o.Type = "Standard";
                    outputs.push(o);
                });

                return { ...state, outputs }
            }
            case actionTypes.updateDynamicInput: {
                const dynamicInputs = action.payload;
                const content = getContent(state.contentText, dynamicInputs, state.content);
                let contentFinal = getFinalContent(content);
                return { ...state, dynamicInputs, content, contentFinal }
            }
            case actionTypes.updateContent: {
                let content = action.payload;
                let contentFinal = getFinalContent(content);
                return { ...state, content, count: new Date(), contentFinal }
            }
            case actionTypes.updateContentText: {
                const contentText = action.payload;
                const content = getContent(contentText, state.dynamicInputs, state.content);
                let contentFinal = getFinalContent(content);
                return { ...state, contentText, content, contentFinal }
            }
            case actionTypes.updateTwoRowContentText: {
                const twoRowContentText = action.payload;
                // console.log(twoRowContentText)
                // const twoRowContent = getTwoRowContent(contentText);
                let contentFinal = getTwoRowContent(twoRowContentText);
                return { ...state, twoRowContentText, contentFinal }
            }
            case actionTypes.setValueFromEdit: {
                const payload = action.payload;
                const contentArray = payload.content.split('|');
                if (contentArray.length === 2) {
                    const twoRowContentText = { firstRow: contentArray[0], secondRow: contentArray[1] };
                    let contentFinal = getTwoRowContent(twoRowContentText);
                    return { ...state, twoRowContentText, contentFinal }
                } else {
                    let response: any = getFinalEditContent(payload.content, payload.inputs);
                    const contentText = response.contentText;
                    const content = response.content;
                    const contentFinal = getFinalContent(content);
                    const dynamicInputs = response.dynamicInputs;
                    return { ...state, content, contentFinal, contentText, dynamicInputs }
                }
            }

            default:
                return state
        }
    }
)

export const actions = {
    updateOutputMessage: (data: any) => ({ type: actionTypes.updateOutputMessage, payload: data }),
    editOutputMessage: (StandardOutputStates: any, PowerOutputStates: any) => ({ type: actionTypes.editOutputMessage, payload: { StandardOutputStates, PowerOutputStates } }),
    updateDynamicInput: (data: any) => ({ type: actionTypes.updateDynamicInput, payload: data }),
    updateContent: (data: any) => ({ type: actionTypes.updateContent, payload: data }),
    updateContentText: (data: any) => ({ type: actionTypes.updateContentText, payload: data }),
    updateTwoRowContentText: (data: any) => ({ type: actionTypes.updateTwoRowContentText, payload: data }),
    setValueFromEdit: (data: any) => ({ type: actionTypes.setValueFromEdit, payload: data }),

}
