import _ from 'lodash';
import React, { useState } from 'react'
import MessageRender from '../../Message/MessageRender';


interface InputProps {
    playlist: any,
    inputSelect?: any,
    handelChangePlaylist: (playlist: any, e: any) => void
}

const AddPlayListRow: React.FC<InputProps> = ({ playlist, handelChangePlaylist, inputSelect }) => {

    return (
        <tr key={playlist.Id}>
            <td>
                <div className='px-4'>
                    <input onChange={(e) => handelChangePlaylist(playlist, e)} name={inputSelect == 'single' ? 'radio' : ''} className="form-check-input small" type={inputSelect == 'single' ? 'radio' : 'checkbox'} value="" id="flexCheckDefault" />
                </div>
            </td>
            <td>{playlist.Title}</td>
            <td>{playlist.Description}</td>
            <td>
                <div className='d-flex flex-row gap-5 flex-wrap'>
                    {playlist.ShowAll && playlist.ShowAll.map((msg: any, i: number) => {
                        return (
                            <React.Fragment key={`img-${playlist.Id}-${i}`} >
                                <MessageRender message={msg}></MessageRender>
                            </React.Fragment>
                        )
                    })}
                    {playlist.ShowFront && playlist.ShowFront.map((msg: any, i: number) => {
                        return (
                            <React.Fragment key={`img-${playlist.Id}-${i}`} >
                                <MessageRender message={msg}></MessageRender>
                            </React.Fragment>
                        )
                    })}
                    {playlist.ShowRear && playlist.ShowRear.map((msg: any, i: number) => {
                        return (
                            <React.Fragment key={`img-${playlist.Id}-${i}`} >
                                <MessageRender message={msg}></MessageRender>
                            </React.Fragment>
                        )
                    })}
                </div>
            </td>
        </tr>
    )
}

export default AddPlayListRow;
