import React, { useState } from 'react'
import _ from 'lodash';
import { KTSVG } from '../../../../_metronic/helpers';
import clsx from 'clsx';

interface props {
    data: any
}

const ZoneBox: React.FC<any> = ({ first, second, third, fourth }) => {

    return (
        <div className='zone-box'>
            <div className={clsx("box", { "active bg-success": first == true })}></div>
            <div className={clsx("box", { "active bg-danger": second == true })}></div>
            <div className={clsx("box", { "active bg-warning": third == true })}></div>
            <div className={clsx("box", { "active bg-warning": fourth == true })}></div>
        </div>
    )
}
const Zones: React.FC<props> = ({ data }) => {


    const isKeep = _.find(data.StandardOutputStates, { State: "keep" });

    if (isKeep) {
        return <><div className='zone-align'><KTSVG path="/media/icons/duotune/arrows/arr002.svg" /></div></>
    }

    const matchNrs = _.filter(data.StandardOutputStates, (o: any) => ((o.Nr >= 1 && o.Nr <= 4) && (o.State == "on" || o.State == "dimmed")));
    const config = {
        first: false,
        second: false,
        third: false,
        fourth: false,
    }

    if (matchNrs && matchNrs.length > 0) {
        matchNrs.map((o: any, i: number) => {
            if (o.Nr == 1) {
                config.first = true;
            } else if (o.Nr == 2) {
                config.second = true;
            } else if (o.Nr == 3) {
                config.third = true;
            } else if (o.Nr == 4) {
                config.fourth = true;
            }
        })
    }

    return <><ZoneBox {...config}></ZoneBox></>

    return <></>
}


const PlaylistMessageZones: React.FC<props> = ({ data }) => {

    return (
        <div className='zone-position'>
            <Zones data={data}></Zones>
        </div>
    )
}

export default PlaylistMessageZones
