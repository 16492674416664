import axios from 'axios'
import { AuthModel } from '../models/AuthModel'
import { UserModel } from '../models/UserModel'

const API_URL = process.env.REACT_APP_API_ENDPOINT

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/prod/auth/get-user`
export const LOGIN_URL = `${API_URL}/prod/api/users/login`
export const REGISTER_URL = `${API_URL}/prod/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/prod/resetPassword`
export const SET_NEW_PASSWORD = `${API_URL}/prod/setPassword`

// Server should return AuthModel
export function login(UserName: string, Password: string) {
  return axios.post(LOGIN_URL, { UserName, Password })
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string, changepassword: string) {
  return axios.post<AuthModel>(REGISTER_URL, {
    email,
    firstname,
    lastname,
    password,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  // const url = `${REQUEST_PASSWORD_URL}?email=${encodeURI(email)}`
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL , {email});
}

export function requestSetPassword(data: any) {
  return axios.post<{ result: boolean }>(SET_NEW_PASSWORD, data)
}
