export default function setupAxios(axios: any, store: any) {
  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: {accessToken, secrateKey},
      } = store.getState()

      if (accessToken) {
        config.headers['access-token'] = accessToken
      }

      if (secrateKey) {
        config.headers['itop-subscription-key'] = secrateKey
      }

      config.headers['accept'] = '*/*'
      config.headers['content-type'] = 'application/json'

      return config
    },
    (err: any) => Promise.reject(err)
  )
}
