import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash';
import { RootState } from '../../../setup'
import { configurationCrudApi, getConfigurations, getPlayList } from '../../redux/api'

import PlaylistTable from './components/PlaylistTable/PlaylistTable'
import ConfirmChanges from './components/ConfirmChanges';
import VerifyCustomer from '../components/LayoutComponent/VerifyCustomer';
import { toast } from 'react-toastify';
import { usePrompt } from '../../../utills/propmt';
import SelectDevice from '../message/components/SelectDevice';
import AddPlayListModal from '../components/AddPlayListModal/AddPlayListModal';
import clsx from 'clsx';

const ConfigurationPage: React.FC<any> = (props: any) => {

  const customerId: any = useSelector<RootState>(({ customer }) => customer.customerId, shallowEqual)
  const customerList: any = useSelector<RootState>(({ customer }) => customer.customerList, shallowEqual);
  const deviceGroup: any = useSelector<RootState>(({ customer }) => customer.deviceGroup, shallowEqual);

  const [configureList, setConfigureList] = useState<any>(null)
  const [configmConfig, setConfigmConfig] = useState<boolean>(false)
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const [playLists, setPlayList] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [isOpenPlaylistModal, setIsOpenPlaylistModal] = useState(false)

  const itemsPerPage = 99999999
  const deviceGroups = customerList[0].DeviceGroups.length > 1 ? customerList[0].DeviceGroups : null;

  useEffect(() => {
    setConfigureList(null)
    if (deviceGroup && deviceGroup.Id) {
      getAllConfiguration(customerId, deviceGroup.Id);
      getAllPlayList(customerId, deviceGroup.Id)
    }
  }, [customerList, customerId, deviceGroup])


  const getAllConfiguration = (
    customerID: any = customerId,
    deviceGroupId: number = deviceGroup?.Id,
    active: boolean = true,
    pageLimit: number = itemsPerPage,
    offset: number = 1
  ) => {
    setIsLoading(true);
    getConfigurations(customerID, deviceGroupId, active, pageLimit, offset).then((res: any) => {
      setIsLoading(false);
      if (res.data.Response.Results && res.data.Response.Results.length > 0) {
        setConfigureList(res.data.Response.Results[0].Configurations[0])
      }
    }).catch((e) => {
      setIsLoading(false)
    })
  }

  const handlePlaylistModal = () => {
    setIsOpenPlaylistModal(!isOpenPlaylistModal)
  }

  const removePlaylist = (index: number) => {
    configureList.Playlists.splice(index, 1);
    setConfigureList(JSON.parse(JSON.stringify(configureList)));
    setIsDirty(true);
  }


  const getAllPlayList = (customerId: any, selectedDeviceGroupId: any
  ) => {
    let reqData: any = {
      limit: 200,
      offset: 1
    }

    setIsLoading(true);

    getPlayList(customerId, reqData)
      .then(({ data }: any) => {
        if (data && data.Response) {
          setPlayList(data.Response.Results)
        }
        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
      })
  }

  const syncConfiguration = () => {
    const ids = _.map(configureList.Playlists, "PlaylistId");

    const requestBody = {
      IncludedPlaylists: ids,
    }

    let toastId = toast.loading("Deploying changes...");


    configurationCrudApi(configureList.ConfigurationId, requestBody).then(({ data }: any) => {
      setIsDirty(false);
      setConfigmConfig(false);
      if (data.Status == 200) {
        toast.update(toastId, { render: "Configuration changes deployed successfully", type: "success", isLoading: false });
      } else {
        toast.update(toastId, { render: data.Message, type: "error", isLoading: false });
      }

      setTimeout(() => {
        toast.dismiss(toastId);
      }, 5000);
    }).catch((e) => {
      setIsDirty(false);
      toast.update(toastId, { render: "Error while deploying configuration", type: "error", isLoading: false });

      setTimeout(() => {
        toast.dismiss(toastId);
      }, 5000);
    })
  }



  const closeSync = () => {
    setConfigmConfig(false);
  }

  const resetConfiguration = () => {
    getAllConfiguration(customerId, configureList.DeviceGroupId);
  }

  const addPlaylist = (plist: any) => {
    const data: any = {
      PlaylistId: plist.Id,
      PlaylistName: plist.Title,
      ConfigurationPlaylistLinkId: plist.ConfigurationPlaylistLinkId,
    }
    configureList.Playlists.push(data);
    setConfigureList(JSON.parse(JSON.stringify(configureList)));
    setIsDirty(true);
  }

  usePrompt("You have unsaved modifications. Do you really want to leave the page without saving them?", isDirty);

  return (
    <>
      <div className={`card playList`}>
        <div className={clsx('card-header border-0', props.isDashboard == true ? 'pt-6' : 'pt-10')}>
          <h1 className='align-items-start d-flex flex-column'>
            <span className={clsx('card-label fw-bolder', props.isDashboard == true ? '' : 'text-primary')}>{props.isDashboard != true ? 'View Current Configuration' : 'My Configuration'}</span>
            {props.isDashboard != true &&
              <>
                <span className='text-muted fw-bold fs-7 mt-5'>
                  {' '}
                  Below is the Content (playlist and messages) that is currently on your iToplights.
                </span>
                <span className='text-muted fw-bold fs-7 mt-2'>
                  {' '}
                  Click to view more details and edit
                </span>
              </>
            }
            {deviceGroups && (
              <span className='text-muted fw-bold fs-7 mt-9'>
                {' '}
                Your have more then one iToplight Group. please select group for which to show the
                configuration.
              </span>
            )}


          </h1>
        </div>
        <div className={clsx('card-body', props.isDashboard == true ? 'pt-0 pb-4' : '')}>

          <VerifyCustomer message="Please select customer first to show configuration.">

            {!configureList && !isLoading && (customerList[0].DeviceGroups?.length == 1 || deviceGroup) &&
              <div className="mb-lg-15 alert alert-danger w-50 mt-5">
                <div className="alert-text font-weight-bold">
                  No configuration has been created for this customer yet. Please contact the system admin.
                </div>
              </div>
            }

            {(customerList?.length == 1 && customerList[0].DeviceGroups?.length > 1) &&
              <SelectDevice />
            }

            {configureList && (
              <PlaylistTable
                configureList={configureList}
                getAllConfiguration={getAllConfiguration}
                playLists={playLists}
                removePlaylist={removePlaylist}
                isDashboard={props.isDashboard}
                type={'normal'}
              />
            )}

            {props.isDashboard != true &&
              <>
                {customerId && customerList?.[0]?.DeviceGroups && configureList && (
                  <>
                    <div className='mt-5  bg-grey p-5'>
                      <button className='btn btn-primary btn-sm' onClick={handlePlaylistModal}>
                        + Add Playlist
                      </button>
                    </div>


                    <div className='mt-5 p-5'>
                      <button className='btn btn-primary btn-sm ' onClick={() => setConfigmConfig(true)}>
                        Confirm changes and Deploy
                      </button>

                      <button className='btn btn-light btn-sm m-5' onClick={resetConfiguration}>
                        Reset
                      </button>
                    </div>
                  </>
                )}

                <AddPlayListModal
                  selectedDeviceGroup={deviceGroup}
                  isOpenPlaylistModal={isOpenPlaylistModal}
                  handlePlaylistModal={handlePlaylistModal}
                  playLists={playLists}
                  selectedList={configureList}
                  addPlaylist={addPlaylist}
                  title={"Add Playlists to Show"}
                  description={<>
                    <span className='text-muted fw-bold fs-7 mt-5 d-block'>
                      {' '}
                      Below is list of your playlist that are the currently not in your configuration.
                    </span>
                    <span className='text-muted fw-bold fs-7 mt-2 d-block'>
                      {' '}
                      Choose playlist below and click the add selected button add them to your configuration.
                    </span>
                    <span className='text-muted fw-bold fs-7 mt-2 d-block'>
                      {' '}
                      they will then be deployed to your iToplights next time they update.
                    </span></>
                  }
                />

                <ConfirmChanges
                  show={configmConfig}
                  confirm={syncConfiguration}
                  close={closeSync}
                />
              </>
            }
          </VerifyCustomer>



        </div>




      </div>
    </>
  )
}

export default ConfigurationPage
