import { Icon } from 'leaflet'
import { useState } from 'react'
import { Marker, Popup, Tooltip, useMap } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import MapDeviceInfo from './MapDeviceInfo'
import React from 'react'

interface InputProps {
  geoData: any,
  outerBounds: any,
  liveTracking?: any,
}

const GeoMarkers: React.FC<InputProps> = ({ geoData, outerBounds, liveTracking }) => {
  const pin = toAbsoluteUrl('/media/svg/itoplight/taxi-map-icon.png')

  const map = useMap();

  React.useEffect(() => {
    if (liveTracking) {
    } else {
      map.fitBounds(outerBounds);
    }
  }, [liveTracking, geoData])

  return (
    <>
      <MarkerClusterGroup showCoverageOnHover={false}>
        {geoData.map((data: any, i: any) => (
          <Marker
            key={i}
            position={[data.Lat, data.Long]}
            icon={
              new Icon({
                iconUrl: pin,
                iconSize: [50, 40],
              })
            }
          >
            {data.CallWord &&
              <Tooltip direction={'right'} permanent={true}>
                {data.CallWord}
              </Tooltip>
            }

            <Popup maxWidth={300} >
              <MapDeviceInfo device={data} ></MapDeviceInfo>
            </Popup>
          </Marker>
        ))}
      </MarkerClusterGroup>

    </>
  )
}


export default GeoMarkers
