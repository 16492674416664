import { Action } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export interface ActionWithPayload<T> extends Action {
    payload?: T
}

export const actionTypes = {
    setAllMessages: '[setAllMessages] Action',
    setSeparateMessages: '[setSeparateMessages] Action',
}



const initialPlaylistState: any = {
    allMessages: [],
    allRequestData: [],
    commonRequestData: [],
    commonData: { frontMessages: [], rearMessages: [] },
    count: 0
}

export const reducer = persistReducer(
    { storage, key: 'v1-dhg-playlist', whitelist: ['playlist'] },
    (state: any = initialPlaylistState, action: any) => {
        let commonData: any = { frontMessages: [], rearMessages: [] };
        switch (action.type) {
            case actionTypes.setAllMessages: {
                const allMessages = action.payload.data;
                const allRequestData: any = [];
                allMessages.map((msg: any, i: number) => {
                    let data: any = {
                        MessageId: msg.Id ? msg.Id : msg.MessageId,
                        ShowDirection: 'all',
                        // ScrollDirection: msg.ScrollDirection,
                        // ScrollSpeed: msg.ScrollSpeed,
                        // Duration: msg.Duration,
                        // Transition: msg.Transition,
                        SequenceNo: i + 1,
                    }

                    if (action.payload.isEdit === true) {
                        msg.Duration = msg.Duration / 1000;
                    }

                    if (msg.ScrollDirection) {
                        data['ScrollDirection'] = msg.ScrollDirection;
                    }
                    if (msg.ScrollSpeed) {
                        data['ScrollSpeed'] = msg.ScrollSpeed
                    }
                    if (msg.Duration) {
                        data['Duration'] = msg.Duration * 1000
                    }
                    if (msg.Transition) {
                        data['Transition'] = msg.Transition
                    }
                    if (msg.Background) {
                        data['Background'] = msg.Background
                    }
                    if (msg.Alignment) {
                        data['Alignment'] = msg.Alignment
                    }

                    if (msg.ScrollDirection) {
                        data['Scroll'] = "on";
                    } else {
                        delete data['Scroll'];
                    }

                    allRequestData.push({ ...data });
                })
                return { ...state, allMessages, allRequestData, count: new Date() }
            };

            case actionTypes.setSeparateMessages: {
                commonData.rearMessages = action.payload.data.rearMessages;
                commonData.frontMessages = action.payload.data.frontMessages;
                let commonRequestData: any = [];

                commonData.frontMessages.map((msg: any, i: number) => {
                    let data: any = {
                        MessageId: msg.Id ? msg.Id : msg.MessageId,
                        ShowDirection: 'front',
                        SequenceNo: i + 1,
                    }

                    if (action.payload.isEdit === true) {
                        msg.Duration = msg.Duration / 1000;
                    }

                    if (msg.ScrollDirection) {
                        data['ScrollDirection'] = msg.ScrollDirection;
                    }
                    if (msg.ScrollSpeed) {
                        data['ScrollSpeed'] = msg.ScrollSpeed
                    }
                    if (msg.Duration) {
                        data['Duration'] = msg.Duration * 1000
                    }
                    if (msg.Transition) {
                        data['Transition'] = msg.Transition
                    }
                    if (msg.Background) {
                        data['Background'] = msg.Background
                    }
                    if (msg.Alignment) {
                        data['Alignment'] = msg.Alignment
                    }

                    if (msg.ScrollDirection) {
                        data['Scroll'] = "on";
                    } else {
                        delete data['Scroll'];
                    }

                    commonRequestData.push({ ...data });
                })

                commonData.rearMessages.map((msg: any, i: number) => {
                    let data: any = {
                        MessageId: msg.Id ? msg.Id : msg.MessageId,
                        ShowDirection: 'rear',
                        SequenceNo: i + 1,
                    }

                    if (action.payload.isEdit === true) {
                        msg.Duration = msg.Duration / 1000;
                    }

                    if (msg.ScrollDirection) {
                        data['ScrollDirection'] = msg.ScrollDirection;
                    }
                    if (msg.ScrollSpeed) {
                        data['ScrollSpeed'] = msg.ScrollSpeed
                    }
                    if (msg.Duration) {
                        data['Duration'] = msg.Duration * 1000
                    }
                    if (msg.Transition) {
                        data['Transition'] = msg.Transition
                    }
                    if (msg.Background) {
                        data['Background'] = msg.Background
                    }
                    if (msg.Alignment) {
                        data['Alignment'] = msg.Alignment
                    }

                    if (msg.ScrollDirection) {
                        data['Scroll'] = "on";
                    } else {
                        delete data['Scroll'];
                    }

                    commonRequestData.push({ ...data });

                })

                return { ...state, commonData, commonRequestData, count: new Date() }
            };

            default:
                return state
        }
    }
)

export const actions = {
    setAllMessages: (data: any, isEdit?: boolean) => ({ type: actionTypes.setAllMessages, payload: { data, isEdit } }),
    setSeparateMessages: (data: any, isEdit?: boolean) => ({ type: actionTypes.setSeparateMessages, payload: { data, isEdit } }),
}
