import { useFormik } from 'formik';
import React, { useState } from 'react'
import { Badge, OverlayTrigger, Popover } from 'react-bootstrap';


const BootUpBadge: React.FC<any> = ({ playlist }) => {

    const popover = (
        <Popover id='delete-popover' className='max-width-300px'>
            <Popover.Body className='custom-tooltip'>
                This playlist will automatically show on your devices when they power on.
                <br />
                You can change this by editing your playlist.
            </Popover.Body>
        </Popover>
    )

    if (playlist?.Bootup == "all" || playlist?.Bootup == "front" || playlist?.Bootup == "rear") {
        return (
            <>
                <OverlayTrigger
                    placement='auto'
                    trigger={['hover', 'focus']}
                    delay={{ show: 0, hide: 50 }}
                    overlay={popover}
                    rootClose
                >
                    <Badge className='bootup-badge' bg="bootup">Bootup: {playlist.Bootup}</Badge>
                </OverlayTrigger>
            </>
        )
    } else {
        return <></>
    }
}

export default BootUpBadge
