import { useFormik } from 'formik';
import React, { useState } from 'react'
import PlaylistMessageZones from '../../playlist/components/PlaylistMessageZones';

interface InputProps {
    message: any,
    showZone?: boolean
}

const MessageImage: React.FC<InputProps> = ({ message, showZone }) => {

    const replaceExtenction = (url: any) => {
        if (url) {
            return url.slice(0, -3) + "png";
        }

        return url;
    }
    return (
        <>
            {showZone &&
                <PlaylistMessageZones data={message}></PlaylistMessageZones>
            }
            <img height={'48px'} style={{ width: 'auto' }} src={replaceExtenction(message.ImageUrl)} ></img>
        </>
    )
}

export default MessageImage
