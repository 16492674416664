import { Action } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export interface ActionWithPayload<T> extends Action {
    payload?: T
}

export const actionTypes = {
    setDragIndex: '[setDragIndex] Action',
    setDragItem: '[setDragItem] Action'
}


const initialDragState: any = {
    dragIndex: null,
    dragItem: null
}



export const reducer = persistReducer(
    { storage, key: 'v1-dhg-drag', whitelist: ['drag'] },
    (state: any = initialDragState, action: any) => {
        switch (action.type) {
            case actionTypes.setDragIndex: {
                const dragIndex = action.payload;
                return { ...state, dragIndex }
            }

            case actionTypes.setDragItem: {
                const dragItem = action.payload;
                return { ...state, dragItem }
            }
            default:
                return state
        }
    }
)

export const actions = {
    setDragIndex: (data: any) => ({ type: actionTypes.setDragIndex, payload: data }),
    setDragItem: (data: any) => ({ type: actionTypes.setDragItem, payload: data }),
}
