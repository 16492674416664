import React from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import moment from 'moment-timezone';

interface inputProps {
    show: boolean,
    close: () => void,
    imageUrl?: string,
}

const MessageFullImagePreview: React.FC<inputProps> = ({ show, close, imageUrl }) => {
    return (
        <>
            <Modal className='message-preview' centered={true} size="lg" show={show} onHide={close}>
                <ModalBody className='text-center overflow-scroll'>
                    <img src={imageUrl}></img>
                </ModalBody>
            </Modal>
        </>
    )
}

export default MessageFullImagePreview;