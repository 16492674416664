import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import AddPlayListTable from './AddPlaylistTable/AddPlayListTable'

interface inputProps {
  isOpenPlaylistModal: any
  handlePlaylistModal: () => void,
  addPlaylist: (data: any) => void,
  selectedDeviceGroup: any,
  playLists: any,
  inputSelect?: any,
  selectedList: any,
  title: any,
  description?: any
}

const AddPlayListModal: React.FC<inputProps> = ({ isOpenPlaylistModal, description, selectedDeviceGroup, handlePlaylistModal, addPlaylist, playLists, selectedList, title, inputSelect }) => {

  return (
    <>
      <Modal size='lg' show={isOpenPlaylistModal} onHide={handlePlaylistModal}>
        <Modal.Body>
          <h1 className='align-items-start d-flex flex-column'>
            <span className='card-label fw-bolder text-primary d-block'>{title}</span>
            {description}
          </h1>
          <AddPlayListTable playLists={playLists} inputSelect={inputSelect} selector={"PlaylistId"} handlePlaylistModal={handlePlaylistModal} addPlaylist={addPlaylist} selectedList={selectedList}></AddPlayListTable>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AddPlayListModal
