import React from 'react';
import PlaylistRow from './PlaylistRow';
import { KTSVG } from '../../../_metronic/helpers';

interface InputProps {
    playlists: any,
    messages: any,
    token: any,
    command: any
}


const MultiList: React.FC<InputProps> = ({ playlists, messages, token, command }) => {
    return (
        <>
            <div className='heading color-primary ml-14'>
                <p className='mb-0 text-uppercase'>View {playlists && playlists.length > 1 ? 'Multilist' : 'Playlist'}: </p>
            </div>
            <div className='ml-14 mb-5'>
                <h1 className='color-primary'>{command.Label}</h1>
                <p className='mb-0'>Showing playlists in {playlists && playlists.length > 1 ? 'multilist' : 'playlist'}.
                    <br />
                    Tap to view playlist content and info.
                </p>
            </div>

            <>
                {command.Lifetime &&
                    <div className='multi-lifetime mb-5 ml-14 color-primary'>
                        <KTSVG
                            path='/media/svg/general/lifetime.svg'
                            className='svg-icon-1 svg-icon-command me-2'
                        />
                        <p className='mb-0'>
                            {playlists && playlists.length > 1 ? 'Multilist' : 'Playlist'} lifetime:&nbsp;
                            <span className='text-dark fw-bolder'>
                                {command.Lifetime ? `${command.Lifetime / 1000} seconds` : '-'}
                            </span>
                        </p>
                    </div>
                }
            </>

            <div className="multi-list mb-5">
                {playlists && (
                    <>
                        <div className='multi-heading'>
                            <span className='first-message'> First message: </span>
                            <span className='playlist-title'> Playlist title: </span>
                            <span className='multi-properties tablet-content'> Properties: </span>
                            <span className='multi-duration mobile-content'> Duration: </span>
                        </div>

                        {playlists?.map((playlist: any, i: any) => {
                            return <PlaylistRow token={token} key={`playlist${i}`} messages={messages} playlist={playlist} index={i} />
                        })}
                    </>
                )}
            </div>
        </>
    )
}

export default MultiList
