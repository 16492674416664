import React from 'react';
import { Modal } from 'react-bootstrap';

interface inputProps {
    isOpenInfoModal: boolean,
    handleCloseInfoModal: () => void,
    message: any,
}

const DynamicKeysInfoModal: React.FC<inputProps> = ({ isOpenInfoModal, handleCloseInfoModal, message }) => {
    return (
        <>
            <Modal show={isOpenInfoModal} onHide={handleCloseInfoModal} centered={true}>
                <Modal.Body className='mb-3'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h3>Dynamic Inputs</h3>
                        <button className='btn' onClick={handleCloseInfoModal}>
                            <i className="fas fa-times fs-1"></i>
                        </button>
                    </div>
                    <div>
                    </div>
                    <>
                        <div className='info-content'>
                            <p className='mx-3'>
                                {message?.DynamicInputs?.length > 0 ?
                                    message?.DynamicInputs?.map((item: any, index: any) => (
                                        <div key={`DynamicInputs-${index}`}>
                                            <span><b>KeyName:</b> {item.KeyName}</span>,&nbsp;
                                            <span><b>DefaultValue:</b> {item.DefaultValue}</span>
                                            {message?.DynamicInputs?.length - 1 != index && ","}
                                        </div>
                                    ))
                                    :
                                    <span>null</span>
                                }
                            </p>
                        </div>
                    </>


                </Modal.Body>
            </Modal>
        </>
    )
}

export default DynamicKeysInfoModal;