import React, { useEffect, useRef } from 'react'
import { getCSS, getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import ApexCharts, { ApexOptions } from 'apexcharts'
import VerifyCustomer from '../../components/LayoutComponent/VerifyCustomer'

interface firmwareVersion {
  firmwareVersion: any
  isError: boolean
}

const FirmwareVersionChart: React.FC<firmwareVersion> = ({ firmwareVersion, isError }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))
    const labels = firmwareVersion?.FirmwareVersions?.map((item: any) => item.Version)
    const percentages = firmwareVersion?.FirmwareVersions?.map((item: any) => item.Percentage)
    const chart = new ApexCharts(chartRef.current, getChartOptions(height, labels, percentages))

    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, firmwareVersion])

  return (
    <>
      <div className='card'>
        <div className='card-header border-0'>
          <h1 className='align-items-start d-flex flex-column mt-6'>
            <span className='card-label fw-bolder'>Firmware Versions</span>
            <span className='text-muted fw-bold fs-7 mt-1'>
              Distribution of Firmware version among device
            </span>
          </h1>
        </div>
        <div className='card-body'>

          <VerifyCustomer message="Please select customer.">
          </VerifyCustomer>

          {!isError && firmwareVersion?.FirmwareVersions && (
            <div ref={chartRef} id='kt_charts_widget_1_chart' style={{ height: '365px' }} />
          )}
        </div>
      </div>
    </>
  )
}

export default FirmwareVersionChart

function getChartOptions(height: any, labels: any, percentages: any) {
  return {
    series: percentages,
    chart: {
      height: height,
      //   width: 'fit-content',
      type: 'pie',
    },
    labels: labels,
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -25
        }
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: any, opts: any) {
        const name = opts.w.globals.labels[opts.seriesIndex]
        return [name, val.toFixed(0) + '%']

        // return [labels[opts.seriesIndex] + ' ' + val.toFixed(0) + '%']
      },
      style: {
        fontWeight: 500,
        colors: ["#000"]
      },
      dropShadow: {
        enabled: false,
      }
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 'auto',
            width: 250,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  }
}
