import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { configurationCrudApi } from '../../../../redux/api'
import AddPlayListRow from './AddPlayListRow'

interface InputProps {
  playLists: any,
  inputSelect?: any,
  handlePlaylistModal: () => void,
  addPlaylist: (data: any) => void,
  selectedList: any
  selector: any
}

const AddPlayListTable: React.FC<InputProps> = ({ playLists, selector, handlePlaylistModal, addPlaylist, selectedList, inputSelect }) => {
  const [playlistIds, setPlaylistIds] = useState<any>([])

  const [filterPlaylists, setFilterPlayList]: any = useState(null);

  useEffect(() => {
    getFilterPlaylists()
  }, [selectedList, playLists])

  const configures = selectedList.Playlists ? selectedList.Playlists : selectedList;

  const getFilterPlaylists = () => {

    const filterList = playLists?.filter((playList: any) => {
      return !configures?.some((item: any) => {
        return item[selector] == playList?.Id
      })
    })


    // const filterList = playLists;

    setFilterPlayList(filterList)
  }


  const handelChangePlaylist = (playlist: any, e: any) => {
    if (inputSelect == 'single') {
      if (e.target.checked) {
        setPlaylistIds(playlist);
      }
    } else {
      if (playlistIds?.length > 0 && !e.target.checked) {
        const filterPlaylists = playlistIds.filter((item: any) => item.Id !== playlist.Id);
        setPlaylistIds(filterPlaylists);
      } else {
        setPlaylistIds((oldArray: any) => [...oldArray, playlist])
      }
    }
  }

  const handlePlaylistAdd = () => {
    if (inputSelect == 'single') {
      addPlaylist(playlistIds);
    } else {
      playlistIds?.map((item: any, index: any) => {
        addPlaylist(item);
      });
    }


    handlePlaylistModal();


  }

  return (
    <>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table align-middle table-row-bordered fs-6 gy-5  no-footer table-striped'>
            <thead>
              <tr className='fw-bolder'>
                <th>Select</th>
                <th>Title</th>
                <th>Description</th>
                <th>Messages</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold'>
              {filterPlaylists && (
                <>
                  {filterPlaylists?.map((pl: any, i: any) => {
                    return (
                      <AddPlayListRow
                        handelChangePlaylist={handelChangePlaylist}
                        key={`pl${i}`}
                        playlist={pl}
                        inputSelect={inputSelect}
                      ></AddPlayListRow>
                    )
                  })}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className='d-flex gap-9'>
        <button className='btn btn-primary btn-sm' onClick={handlePlaylistAdd}>Add Selected</button>
        <button className='btn btn-secondary btn-sm' onClick={handlePlaylistModal}>Cancel</button>
      </div>
    </>
  )
}

export default AddPlayListTable
