/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { getGeoStatus } from '../../redux/api'
import { LoadingBlock } from '../../staticComponents/LoadingBlock'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import MapView from './components/MapView'
import { toast } from 'react-toastify'

const DeviceMapPage: React.FC<any> = ({ height }) => {

    const [geoData, setGeoData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const customerId: any = useSelector<RootState>(({ customer }) => customer.customerId, shallowEqual);
    const [liveTracking, setLiveTracking] = useState<boolean>(false);

    // Define the function that needs to be called every 5 seconds
    const liveTrack = () => {
        getLocations(customerId);    
        // Place your function code here
    };

    const getLocations = (data: any | null = null) => {
        let requestData = {
            CustomerIds: data,
        }

        if (data === undefined || data === null) {

            requestData = {
                CustomerIds: [1, 2],
            }

            if (customerId && customerId.length > 0 && process.env.REACT_APP_ENV === 'production') {
                requestData.CustomerIds = customerId;
            }
        }

        setIsLoading(true)
        getGeoStatus(requestData)
            .then(({ ...data }: any) => {
                setIsLoading(false)
                if (data.data && data.data.Response && data.data.Response.Devices) {
                    setGeoData(data.data.Response.Devices)
                } else if (data.data && (!data.data.Response || data.data.Status == 406)) {
                    toast.error("No device data found");
                    setGeoData([])
                } else if (!data.data) {
                    toast.error(data.response.data.Message);
                    setGeoData([])
                }
            })
            .catch((e: any) => {
                setIsLoading(false)
            })
    }

    React.useEffect(() => {
        getLocations(customerId);
        setLiveTracking(false);
    }, [customerId])

    React.useEffect(() => {
        let intervalId: any;
        if (liveTracking) {
            // Call the function every 5 seconds
            intervalId = setInterval(liveTrack, 5000);
        } else {
            // Stop the interval
            clearInterval(intervalId);
        }
        return () => clearInterval(intervalId);
    }, [liveTracking])

    // getGeoStatus
    return (
        <div className='dashboard-page'>
            {/* <div className='page-title'> CAMPAIGN OVERVIEW </div> */}



            <div className='d-flex flex-row device-header'>

                {/* <div className='col-md-8'> */}
                <div className='map-block' style={{ width: '100%', height: height ? '490px' : '75vh' }}>
                    {isLoading ? <>
                        <div className='map-loading-overlay'>
                            <LoadingBlock></LoadingBlock>
                        </div>
                    </> : <></>}
                    <MapView geoData={geoData} liveTracking={liveTracking} setLiveTracking={setLiveTracking} customerId={customerId}></MapView>
                </div>

            </div>
        </div>
    )
}


export default DeviceMapPage;