export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname: string, url: string, compareHard: boolean = false) {
  const current = getCurrentUrl(pathname)
  if (!current || !url) {
    return false
  }

  if (current === url) {
    return true
  }

  const currentUrlArray = current.split("/");
  const urlArray = url.split("/");

  if (compareHard) {

    if (currentUrlArray[1] === urlArray[1]) {
      return true
    }
  }

  return false
}
