import React, { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { requestSetPassword } from '../redux/AuthCRUD'
import { LoadingBlock } from '../../../staticComponents/LoadingBlock'
import { verifyEmail } from '../../../redux/api'

const initialValues = {
  password: '',
  confirmPassword: '',
}

const forgotPasswordSchema = Yup.object().shape({
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
})

export function SetPassword() {
  const [loading, setLoading] = useState(false)
  const history = useNavigate()
  const [isVerifying, setIsVerifying] = useState<any>(true)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const email = queryParams.get('e')
    const token = queryParams.get('k')

    const data = {
      Email: email,
      Key: token,
    }

    verifyEmail(data)
      .then((data) => {
        if (data.data && data.data.Response === true) {
          setIsVerifying(false)
        } else {
          setIsVerifying(data.data.Message)
        }
      })
      .catch((e) => {
        setIsVerifying('error')
      })
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        const queryParams: any = new URLSearchParams(window.location.search)
        const email = encodeURI(queryParams.get('e'))
        const token = encodeURI(queryParams.get('k'))

        const data = {
          Email: email,
          Key: token,
          Password: values.password,
        }

        requestSetPassword(data)
          .then(({ data }) => {
            setHasErrors(false)
            setLoading(false)
            history('/auth/login')
          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
            setStatus('Error while setting up password')
          })
        }, 1000)
      },
    })
    
    if (isVerifying === true) {
    return (
      <>
        <div className='h-150px'>
          <LoadingBlock></LoadingBlock>
          <div className='text-center'>Verifying ...</div>
        </div>
      </>
    )
  }
 
  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Set Password</h1>
          {/* end::Title */}

          {/* begin::Link */}
          {/* <div className='text-gray-400 fw-bold fs-4'>Enter your email to reset your password.</div> */}
          {/* end::Link */}
        </div>

        {isVerifying !== false && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              {isVerifying}
            </div>
          </div>
        )}

        {isVerifying === false && (
          <div>
            {/* begin::Title */}
            {hasErrors === true && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>
                  Sorry, looks like there are some errors detected, please try again.
                </div>
              </div>
            )}

            {hasErrors === false && (
              <div className='mb-10 bg-light-info p-8 rounded'>
                <div className='text-info'>Sent password reset. Please check your email</div>
              </div>
            )}
            {/* end::Title */}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
              <label className='form-label fw-bolder text-gray-900 fs-6'>Password</label>
              <input
                type='password'
                placeholder=''
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  { 'is-invalid': formik.touched.password && formik.errors.password },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='fv-row mb-10'>
              <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm Password</label>
              <input
                type='password'
                placeholder=''
                autoComplete='off'
                {...formik.getFieldProps('confirmPassword')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  { 'is-invalid': formik.touched.password && formik.errors.confirmPassword },
                  {
                    'is-valid': formik.touched.password && !formik.errors.confirmPassword,
                  }
                )}
              />
              {formik.touched.password && formik.errors.confirmPassword && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.confirmPassword}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
              {!loading ? (
                <>
                  <button
                    type='submit'
                    id='kt_password_reset_submit'
                    className='btn btn-lg btn-primary fw-bolder me-4'
                  >
                    <span className='indicator-label'>Submit</span>
                  </button>
                  <Link to='/auth/login'>
                    <button
                      type='button'
                      id='kt_login_password_reset_form_cancel_button'
                      className='btn btn-lg btn-light-primary fw-bolder'
                      disabled={formik.isSubmitting || !formik.isValid}
                    >
                      Cancel
                    </button>
                  </Link>
                </>
              ) : (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </div>
          </div>
        )}

        {/* end::Form group */}
      </form>
    </>
  )
}
