import { useFormik } from 'formik';
import React, { useState } from 'react'
import PlaylistMessageZones from '../../playlist/components/PlaylistMessageZones';

interface InputProps {
    message: any,
    showZone?: boolean
}


const MessageBitmap: React.FC<InputProps> = ({ message, showZone }) => {

    return (
        <>
            {showZone &&
                <PlaylistMessageZones data={message}></PlaylistMessageZones>
            }
            <div style={{ padding: '3.5px 3px', fontSize: "16px", background: "#000", color: `rgb(${message.DisplayColor.r},${message.DisplayColor.g},${message.DisplayColor.b})` }} className="h-48px text-center">
                <strong>
                    {message.Description}
                </strong>
            </div>
        </>
    )
}

export default MessageBitmap
