import { Action } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export interface ActionWithPayload<T> extends Action {
    payload?: T
}

export const actionTypes = {
    SetCustomer: '[SetCustomer] Action',
    setDeviceGroup: '[SetDevicegroup] Action',
    ClearSelection: '[ClearSelection] Action',
    setAllCustomer: '[setAllCustomer] Action',
    setTestDevice: '[setTestDevice] Action',
}



let customerId = localStorage.getItem('customerId')
let customerList = localStorage.getItem('customerList')
let deviceGroup = localStorage.getItem('group')
let testDevice = localStorage.getItem('testDevice')
let allCustomers: any = [];


if (customerId !== null) {
    customerId = JSON.parse(atob(customerId));
}
if (customerList !== null) {
    customerList = JSON.parse(atob(customerList));
}

if (deviceGroup !== null) {
    deviceGroup = JSON.parse(atob(deviceGroup));
}

if (testDevice !== null) {
    testDevice = JSON.parse(atob(testDevice));
}

const initialCustomerState: any = {
    customerId: customerId ? customerId : null,
    customerList: customerList ? customerList : null,
    deviceGroup: deviceGroup ? deviceGroup : null,
    allCustomers: allCustomers ? allCustomers : null,
    testDevice: testDevice ? testDevice : null
}

export const reducer = persistReducer(
    { storage, key: 'v1-dhg-customer', whitelist: ['customer'] },
    (state: any = initialCustomerState, action: any) => {
        switch (action.type) {
            case actionTypes.SetCustomer: {
                const customerId = action.ids;
                const customerList = action.customerList;
                let deviceGroup = null;

                localStorage.setItem("customerId", btoa(JSON.stringify(action.ids)));
                localStorage.setItem("customerList", btoa(JSON.stringify(action.customerList)))
                localStorage.removeItem("group");

                if (customerList.length == 1 && customerList[0].DeviceGroups && customerList[0].DeviceGroups.length == 1) {
                    deviceGroup = customerList[0].DeviceGroups[0];
                    localStorage.setItem("group", btoa(JSON.stringify(deviceGroup)))
                }
                return { ...state, customerId, customerList, deviceGroup }
            }
            case actionTypes.setDeviceGroup: {
                const deviceGroup = action.deviceGroup;
                localStorage.setItem("group", btoa(JSON.stringify(deviceGroup)));
                return { ...state, deviceGroup }
            }
            case actionTypes.ClearSelection: {
                return { ...state, deviceGroup: null, customerId: null, customerList: null, allCustomers: [], testDevice: null }
            }

            case actionTypes.setAllCustomer: {
                return { ...state, allCustomers: action.allCustomer }
            }

            case actionTypes.setTestDevice: {
                localStorage.setItem("testDevice", btoa(JSON.stringify(action.testDevice)));
                return { ...state, testDevice: action.testDevice }
            }

            default:
                return state
        }
    }
)

export const actions = {
    setCustomer: (ids: any, customerList: any) => ({ type: actionTypes.SetCustomer, ids: ids, customerList: customerList }),
    setAllCustomer: (allCustomer: any) => ({ type: actionTypes.setAllCustomer, allCustomer: allCustomer }),
    setDeviceGroup: (deviceGroup: any) => ({ type: actionTypes.setDeviceGroup, deviceGroup }),
    clearSelection: () => ({ type: actionTypes.ClearSelection, deviceGroup }),
    setTestDevice: (testDevice: any) => ({ type: actionTypes.setTestDevice, testDevice: testDevice }),
}
