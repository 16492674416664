import React from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers';


const CreditIcon: React.FC = () => {

    return (
        <>
            <img
                src={toAbsoluteUrl(`/media/icons/duotone/General/credit.svg`)}
                alt='credit'
                className={`w-20px mb-1 cursor-pointer`}
            />
        </>
    )
}

export default CreditIcon
