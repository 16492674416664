/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { AsideMenuItem } from './AsideMenuItem'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'

interface InputProps {
  settings: any,
}

type Props = {
  settings: any
}

const AsideMenuMain: React.FC<Props> = ({ settings }) => {

  const intl = useIntl()


  const getIconsFromMenu = (titleText: string) => {
    let title = titleText.toLowerCase();
    
    if (title === 'dashboard') {
      return '/media/icons/duotone/Design/PenAndRuller.svg';
    } else if (title === 'devices') {
      return '/media/icons/duotone/Devices/itop_device.svg';
    } else if (title === 'configuration') {
      return '/media/icons/duotune/coding/cod001.svg';
    } else if (title === 'playlists') {
      return '/media/icons/duotone/Media/Playlist2.svg';
    } else if (title === 'messages') {
      return '/media/icons/duotone/Design/Image.svg';
    } else if (title === 'statistics') {
      return '/media/icons/duotune/general/gen032.svg';
    } else if (title === 'store') {
      return '/media/icons/duotune/ecommerce/ecm004.svg';
    } else if (title === 'customers') {
      return '/media/icons/duotune/communication/com014.svg';
    } else if (title === 'app control buttons') {
      return '/media/icons/duotune/electronics/elc002.svg';
    } else if (title === 'schedules') {
      return '/media/icons/duotone/Code/Time-schedule.svg';
    } else if (title === 'geozones') {
      return '/media/icons/duotone/Design/geozone-icon.svg';
    } else if (title === 'learn') {
      return '/media/icons/duotone/Code/Question-circle.svg';
    }

    return "/media/icons/duotone/Devices/Server.svg";

  }


  return (
    <>
      {settings.menu.map((menu: any, i: any) => {
        return <React.Fragment key={i}>
          {menu.items ?
            <AsideMenuItemWithSub
              to={menu.url}
              icon={getIconsFromMenu(menu.title)}
              title={menu.title}
              fontIcon='bi-app-indicator'
            >
              {menu.items.map((subMenu: any, j: any) => {
                return <React.Fragment key={`sub-${j}`}><AsideMenuItem key={j} to={subMenu.url} title={subMenu.title} hasBullet={true} /></React.Fragment>
              })}
            </AsideMenuItemWithSub>

            :

            <AsideMenuItem
              to={menu.url}
              icon={getIconsFromMenu(menu.title)}
              title={menu.title}
              fontIcon='bi-app-indicator'
            />
          }

        </React.Fragment>
      })}



      {/* <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItemWithSub
        to='/device'
        icon='/media/icons/duotone/Devices/Server.svg'
        title={intl.formatMessage({ id: 'MENU.DEVICE' })}
        fontIcon='bi-app-indicator'
      >
        <AsideMenuItem to='/device/list' title='List' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/statistics'
        icon='/media/icons/duotune/general/gen032.svg'
        title={intl.formatMessage({ id: 'MENU.STATISTIC' })}
        fontIcon='bi-app-indicator'
      >
        <AsideMenuItem to='/statistics/status-logs' title='Status Logs' hasBullet={true} />
      </AsideMenuItemWithSub> */}
    </>
  )
}

export { AsideMenuMain };