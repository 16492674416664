import React from 'react'
import MessageRender from '../../modules/components/Message/MessageRender'
import MessageZones from '../../modules/message/components/MessageTable/MessageZones'

interface props {
    message: any
}

const Message: React.FC<props> = ({ message }) => {

    const rgbToColor = ({ r, g, b }: any) => {
        return `rgb(${r}, ${g}, ${b})`;
    };

    return (
        <>
            <div className="playlist-container" key={`message${message.Id}`}>
                <div className='dynamic-message'>
                    <div className='message-image'>
                        <MessageRender message={message} />
                    </div>
                    {message.DynamicInputs && message.DynamicInputs.length > 0 &&
                        < div className='dynamic'>
                            <span>[...]</span>
                            <span>DYNAMIC</span>
                        </div>
                    }
                </div>
                <div className='playlist-data'>
                    <div className="playlist-item">
                        <div className="label">Type:</div>
                        <div className="value">
                            {message.Type ? message.Type.charAt(0).toUpperCase() + message.Type.slice(1) : '-'}
                        </div>
                    </div>
                    <div className="playlist-item">
                        <div className="label">Align:</div>
                        <div className="value">
                            {message.Alignment ? message.Alignment.charAt(0).toUpperCase() + message.Alignment.slice(1) : '-'}
                        </div>
                    </div>
                    <div className="playlist-item">
                        <div className="label">Duration:</div>
                        <div className="value">
                            {message.Duration ? `${message.Duration / 1000}s` : '-'}
                        </div>
                    </div>
                    <div className="playlist-item">
                        <div className="label">Light zones:</div>
                        <div className="value"><MessageZones data={message} /></div>
                    </div>
                    <div className="playlist-item">
                        <div className="label">Scroll:</div>
                        <div className="value">
                            {message.ScrollDirection ? message.ScrollDirection.charAt(0).toUpperCase() + message.ScrollDirection.slice(1) : '-'}
                        </div>
                    </div>
                    <div className="playlist-item">
                        <div className="label">Speed:</div>
                        <div className="value">
                            {message.ScrollSpeed === 16 ? 'Fast'
                                : message.ScrollSpeed === 20 ? 'Standard'
                                    : message.ScrollSpeed === 24 ? 'Slow'
                                        : message.ScrollSpeed ? message.ScrollSpeed : '-'}
                        </div>
                    </div>
                    <div className="playlist-item">
                        <div className="label">Transition:</div>
                        <div className="value">
                            {message.Transition === 'in' ? 'In'
                                : message.Transition === 'out' ? 'Out'
                                    : message.Transition === 'in_out' ? 'In-Out'
                                        : message.Transition === 'in_pause_out' ? 'In-Pause-Out'
                                            : message.Transition ? message.Transition : '-'}
                        </div>
                    </div>
                    <div className="playlist-item">
                        <div className="label">Background:</div>
                        <div className="value color-block">
                            <div className="color-palette" style={{ background: message.Background ? rgbToColor(message.Background) : 'rgb(0, 0, 0)' }}>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Message
