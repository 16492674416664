import React, { useState } from 'react'
import { Badge, OverlayTrigger, Popover } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const DeviceBadge: React.FC<any> = ({ playlist }) => {
    const [showPopover, setShowPopover] = useState(false);

    let isHovering = false;
    let bgColor, callWord
    if (playlist?.CallWord == "vacant") {
        bgColor = 'vacant-badge'
        callWord = 'Vacant Trig'
    }

    if (playlist?.CallWord == "hired") {
        bgColor = 'hired-badge'
        callWord = 'Hired Trig'
    }

    const handleMouseEnter = () => {
        setShowPopover(true);
    };

    const handleMouseLeave = () => {
        setTimeout(() => {
            if (!isHovering) {
                setShowPopover(false);
            }
        }, 55);
    };


    const onPopoverEnter = () => {
        isHovering = true;
        setShowPopover(true);
    };

    const onPopoverLeave = () => {
        isHovering = false;
        handleMouseLeave();
    };


    const vacantPopover = (
        <Popover id='vacant-popover' className='max-width-300px' onMouseEnter={onPopoverEnter}
            onMouseLeave={onPopoverLeave}>
            <Popover.Body className='custom-tooltip'>
                This playlist uses the "vacant" callword,
                <br />
                and can be triggered automatically
                <br />
                through taxameter integration.
                <br />
                <br />
                <Link to={`/faq`} target="_blank" className="text-primary fw-bolder text-underline">Read more about trigs in our FAQ</Link>
            </Popover.Body>
        </Popover>
    )

    const hiredPopover = (
        <Popover id='hired-popover' className='max-width-300px' onMouseEnter={onPopoverEnter}
            onMouseLeave={onPopoverLeave}>
            <Popover.Body className='custom-tooltip'>
                This playlist uses the "hired" callword,
                <br />
                and can be triggered automatically
                <br />
                through taxameter integration.
                <br />
                <br />
                <Link to={`/faq`} target="_blank" className="text-primary fw-bolder text-underline">Read more about trigs in our FAQ</Link>
            </Popover.Body>
        </Popover>
    )

    const getPopover = () => {
        let popover: any
        if (playlist?.CallWord == "vacant") {
            popover = vacantPopover;
        }

        if (playlist?.CallWord == "hired") {
            popover = hiredPopover;
        }

        return popover;
    }

    if (playlist?.CallWord == "vacant" || playlist?.CallWord == "hired") {
        return (
            <>
                <OverlayTrigger
                    placement='auto'
                    trigger={['hover', 'focus']}
                    show={showPopover}
                    overlay={getPopover()}
                    rootClose
                >
                    <Badge onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave} className={`bootup-badge ${bgColor}`}> {callWord}</Badge>
                </OverlayTrigger>
            </>
        )
    } else {
        return <></>
    }
}

export default DeviceBadge
