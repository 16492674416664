import React from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import moment from 'moment-timezone';

interface inputProps {
    isOpenInfoModal: boolean,
    handleCloseInfoModal: () => void,
    message: any,
    fullInfo?: boolean,
}

const MessageInfoModal: React.FC<inputProps> = ({ isOpenInfoModal, handleCloseInfoModal, message, fullInfo }) => {
    return (
        <>
            <Modal show={isOpenInfoModal} onHide={handleCloseInfoModal}>
                <ModalBody className='mb-3'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h3>Message Information</h3>
                        <button className='btn' onClick={handleCloseInfoModal}>
                            <i className="fas fa-times fs-1"></i>
                        </button>
                    </div>
                    <div>
                    </div>
                    {fullInfo ?
                        <>
                            <div className='d-flex mt-7 info-content'>
                                <h5>Message Id: &nbsp;</h5>
                                <p>{message?.Id}</p>
                            </div>
                            <div className='d-flex info-content'>
                                <h5>Device Group Id:</h5>
                                <p>{message?.DeviceGroupId}</p>
                            </div>
                            <div className='d-flex info-content'>
                                <h5>Playlist Ids: &nbsp;</h5>
                                <p>{message?.PlaylistIds?.map((item: any, index: any) => <span key={`PlaylistId-${item}`}>{item} {message?.PlaylistIds?.length - 1 != index && ","} &nbsp;</span>)}</p>
                            </div>
                            <div className='d-flex info-content'>
                                <h5>Playlist Titles: &nbsp;</h5>
                                <p>{message?.PlaylistTitles?.map((item: any, index: any) => <span key={`PlaylistTitle-${item}`}>{item} {message?.PlaylistTitles?.length - 1 != index && ","} &nbsp;</span>)}</p>
                            </div>
                            <div className='d-flex info-content'>
                                <h5>Slot: &nbsp;</h5>
                                <p>{message?.Slot}</p>
                            </div>
                            <div className='d-flex info-content'>
                                <h5>Font Id: &nbsp;</h5>
                                <p>{message?.FontId}</p>
                            </div>
                            <div className='d-flex info-content'>
                                <h5>Content: &nbsp;</h5>
                                <p>{message?.Content}</p>
                            </div>
                            <div className='d-flex info-content'>
                                <h5>Display Color: &nbsp;</h5>
                                <p>{`r: ${message?.DisplayColor?.r}, g: ${message?.DisplayColor?.g}, b: ${message?.DisplayColor?.b}`}</p>
                            </div>
                            <div className='info-content'>
                                <h5>Standard Light zone:</h5>
                                <p className='mx-3'>
                                    {message?.StandardOutputStates?.length > 0 ?
                                        message?.StandardOutputStates?.map((item: any, index: any) => (
                                            <div key={`StandardOutputStates-${index}`}>
                                                <span><b>Nr:</b> {item.Nr}</span>&nbsp;
                                                <span><b>State:</b> {item.State}</span>
                                                {message?.StandardOutputStates?.length - 1 != index && ","}
                                            </div>
                                        ))
                                        :
                                        <span>null</span>
                                    }
                                </p>
                            </div>
                            <div className='d-flex info-content'>
                                <h5>Power Light zone:</h5>
                                <p className='mx-3'>
                                    {message?.PowerOutputStates?.length > 0 ?
                                        message?.PowerOutputStates?.map((item: any, index: any) => (
                                            <div key={`PowerOutputStates-${index}`}>
                                                <span><b>Nr:</b> {item.Nr}</span>&nbsp;
                                                <span><b>State:</b> {item.State}</span>
                                                {message?.PowerOutputStates?.length - 1 != index && ","}
                                            </div>
                                        ))
                                        :
                                        <span>null</span>
                                    }
                                </p>
                            </div>
                            <div className='info-content'>
                                <h5>Dynamic Inputs: &nbsp;</h5>
                                <p className='mx-3'>
                                    {message?.DynamicInputs?.length > 0 ?
                                        message?.DynamicInputs?.map((item: any, index: any) => (
                                            <div key={`DynamicInputs-${index}`}>
                                                <span><b>KeyName:</b> {item.KeyName}</span>&nbsp;
                                                <span><b>DefaultValue:</b> {item.DefaultValue}</span>
                                                {message?.DynamicInputs?.length - 1 != index && ","}
                                            </div>
                                        ))
                                        :
                                        <span>null</span>
                                    }
                                </p>
                            </div>
                            <div className='d-flex info-content'>
                                <h5>Expiry Unix Time: &nbsp;</h5>
                                <p>{message?.ExpiryUnixTime ? !Array.isArray(message?.ExpiryUnixTime) && moment(message.ExpiryUnixTime).format('DD MMM YYYY') : "null"}</p>
                            </div>
                            <div className='d-flex info-content'>
                                <h5>Last Updated: &nbsp;</h5>
                                <p>{message?.LastUpdated ? !Array.isArray(message?.LastUpdated) && moment(message.LastUpdated).format('DD MMM YYYY') : "null"}</p>
                            </div>
                        </>
                        :
                        <>
                            <div className='d-flex mt-7 info-content'>
                                <h5>Id: &nbsp;</h5>
                                <p>{message?.Id ? message?.Id : message?.MessageId}</p>
                            </div>
                            <div className='d-flex info-content'>
                                <h5>Description: &nbsp;</h5>
                                <p>{message?.Description}</p>
                            </div>
                            <div className='d-flex info-content'>
                                <h5>Type: &nbsp;</h5>
                                <p>{message?.Type}</p>
                            </div>
                            <div className='d-flex info-content'>
                                <h5>Color (R,G,B): &nbsp;</h5>
                                {message?.DisplayColor &&
                                    <p>{`${message?.DisplayColor?.r}, ${message?.DisplayColor?.g}, ${message?.DisplayColor?.b}`}</p>
                                }
                            </div>
                            <div className='info-content'>
                                <h5>Standard Output:</h5>
                                <p className='mx-3'>
                                    {message?.StandardOutputStates?.length > 0 ?
                                        message?.StandardOutputStates?.map((item: any, index: any) => (
                                            <span key={`StandardOutputStates-${index}`}>
                                                Nr{item.Nr} : {item.State} {index < message.StandardOutputStates.length - 1 ? ',  s' : ''}
                                            </span>
                                        ))
                                        :
                                        <span>null</span>
                                    }
                                </p>
                            </div>
                            <div className='d-flex info-content'>
                                <h5>Content: &nbsp;</h5>
                                <p>{message?.Content}</p>
                            </div>
                            <div className='d-flex info-content'>
                                <h5>SlotId: &nbsp;</h5>
                                <p>{message?.SlotID ? message?.SlotID : message?.Slot}</p>
                            </div>
                        </>
                    }

                </ModalBody>
            </Modal>
        </>
    )
}

export default MessageInfoModal;