import React, { VFC } from 'react';
import { Button, Modal } from 'react-bootstrap';

const ExitDialog: VFC<{
    message: string;
    open: boolean;
    onConfirm: Function;
    onClose: Function;
}> = ({ open, onConfirm, onClose, message }) => {
    const handleConfirm = () => {
        onConfirm();
        onClose();
    };

    return (
        <Modal show={open} centered={true} >
            <Modal.Header className='p-8 pt-5 pb-5 m-0'>
                <h4>Leave the page?</h4>
            </Modal.Header>
            <Modal.Body>
                {message}
            </Modal.Body>
            <Modal.Footer className='p-3 m-0'>
                <Button className='btn-sm' onClick={handleConfirm}>Yes, leave and discard</Button>
                <Button className='btn-sm' onClick={() => onClose()}>No, stay and edit</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ExitDialog;
