



const ExtrernalUrls: React.FC<any> = ({ url }) => {
    return (
        <>
            <div className={`card tutorials h-100`}>
                <iframe title="iFrame" width="100%" height="100%" src={url}></iframe>
            </div>
        </>
    )
}

export default ExtrernalUrls
