import React from 'react'
import { KTSVG } from '../../../../_metronic/helpers'

interface props {
  totalDemoLights: number,
  totalPlaylist: number,
  totalMessages: number,
  totalAdViews: number
}

const Cards: React.FC<props> = (props) => {
  return (
    <>
      <div className='row g-10 dashboard-cards'>
        <div className='col-md-3 col-sm-6 col-12'>
          <div className='card demolight py-4 px-4 bg-info text-white'>
            <KTSVG path='/media/icons/duotone/Devices/itop_device.svg' className='text-white svg-icon-5hx' />
            <div className='px-3'>
              <div className='fs-3 fw-bold mt-3'>{props.totalDemoLights}</div>
              <div className='fs-5 fw-bold'>Itoplight Domelights</div>
            </div>
          </div>
        </div>
        <div className='col-md-3 col-sm-6 col-12'>
          <div className='card playlist py-4 px-4 bg-primary text-white'>
            <KTSVG path="/media/icons/duotune/coding/cod001.svg" className="svg-icon-4hx" />
            <div className='px-3'>
              <div className='fs-3 fw-bold mt-3'>{props.totalPlaylist}</div>
              <div className='fs-5 fw-bold'>Playlists</div>
            </div>
          </div>
        </div>
        <div className='col-md-3 col-sm-6 col-12'>
          <div className='card message py-4 px-4 bg-danger text-white'>
            <KTSVG path="/media/icons/duotune/communication/com007.svg" className="svg-icon-4hx" />
            <div className='px-3'>
              <div className='fs-3 fw-bold mt-3'>{props.totalMessages}</div>
              <div className='fs-5 fw-bold'>Messages</div>
            </div>
          </div>
        </div>
        <div className='col-md-3 col-sm-6 col-12'>
          <div className='card adviews py-4 px-6  bg-success text-white'>
            <KTSVG path='/media/icons/duotone/General/ad-icon.svg' className='text-white svg-icon-4hx'/>
            <div className='fs-3 fw-bold mt-3'>{props.totalAdViews}</div>
            <div className='fs-5 fw-bold'>Ad-view hours</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Cards
