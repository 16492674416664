import React from 'react'
import { Button, Modal } from 'react-bootstrap'

interface inputProps {
    isOpenModal?: boolean
    close?: () => void
    confirm?: () => void
    message?: any
}

const PlaylistEditConfirmation: React.FC<inputProps> = ({
    isOpenModal,
    close,
    confirm,
    message,
}) => {
    return (
        <Modal size='lg' show={isOpenModal} onHide={close} confirm={confirm}>
            <Modal.Body>
                <h1 className='text-primary'>Update a playlist on your iToplight devices?</h1>
                <h6 className='fw-1 mt-5'>
                    Warning:
                </h6>
                <p>
                    You are about to edit a playlist that is currently in your active configuration. i.e., that is currently used on your iToplight devices.
                    if you save your edits, your iToplights will be updated with the new version of this playlist at next update.
                </p>

                <strong>Are you sure you want to proceed ?</strong>

                <div className='d-flex gap-5 mt-7'>
                    <Button size='sm' variant='secondary' onClick={close}>
                        Cancel
                    </Button>
                    <Button size='sm' variant='primary' onClick={confirm}>
                        Yes, continue to the Edit page
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default PlaylistEditConfirmation
